import { Box } from "@mui/material";
import { Layout } from "../Components/layout";
import { ProductDetails } from "../Components/product_management/product_details";
import { DeliveryManagement } from "../Components/delivery_management/delivery_management";
import { UserManagement } from "../Components/user_management";
import { Sidebar } from "../Components/sidebar";
import { Header } from "../Components/header";
import { Dashboard } from "../Components/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Inventory } from "../Components/product_management/inventory";
import { Campaign } from "../Components/product_management/campaign";
import { OrederManagement } from "../Components/order_management/order_lists";
import axios from 'axios';
import { useEffect } from "react";
import { updateFirst_name, updateId, updateLast_name } from "../Redux/user";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useGetOrders } from "../Components/globalFunctions"
import { updatesocketStatus } from "../Redux/page";
import sound  from '../Assets/notification_tone.mp3'
export function Home() {
    const page = useSelector(state => state.page.pageName)
    console.log(page)
    const id = localStorage.getItem('id');
    const authToken = localStorage.getItem('authtoken');
    const first_name = localStorage.getItem('first_name');
    const last_name = localStorage.getItem('last_name');
    const toast = useRef(null);
    const socketUrl = process.env.REACT_APP_SOCKET_URL
    console.log(id)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(updateFirst_name(first_name))
        dispatch(updateLast_name(last_name));
        dispatch(updateId(id));

  },[authToken])
  const alarm = () => {
    new Audio(sound).play()
  }
  const GetOrders = useGetOrders()
  useEffect(() => {
    console.log("socketUrl",socketUrl)
      const ws = new WebSocket(`wss://${socketUrl}/ws/admin/orders/`); 
  
      ws.onopen = () => {
        console.log('WebSocket connected');
        ws.send(JSON.stringify({ source: 'request.list' }));
        dispatch(updatesocketStatus(true))
        GetOrders()
      };
  
      ws.onmessage = (event) => {
        const parsedMessage = JSON.parse(event.data);
        console.log('Message received:', parsedMessage);
        if(parsedMessage.message=='Recieved new Order'){
          alarm()
        }
        GetOrders()
        
      };
  
      ws.onerror = (error) => {
        console.error('WebSocket error:', error.message);
        dispatch(updatesocketStatus(false))
      };
  
      ws.onclose = () => {
        console.log('WebSocket connection closed');
        dispatch(updatesocketStatus(false))
      };
  
  
      return () => {
        ws.close();
        console.log('WebSocket closed on unmount');
        dispatch(updatesocketStatus(false))
      };
    }, [authToken]);
    return (<Box sx={{ width: "100%", height: "100vh", backgroundColor: 'background.default' }}>
        <Header />
      <Toast ref={toast}/>
        <Box sx={{ display: "flex",height:'calc( 100vh - 72px)',overflowY:'auto' }}>
            <Sidebar/>
            <Box sx={{width:"100%" }}>
                {page === "dashboard" && <Dashboard />}

                {page === "product_details" && <ProductDetails />}
                {page === "campaign" && <Campaign />}
                {page === "inventory" && <Inventory />}

                {page === "order_management" && <OrederManagement />}
                {page === "delivery_management" && <DeliveryManagement />}
                {page === "user_management" && <UserManagement />}
            </Box>

        </Box>
    </Box>)
}