import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    orders: [],
    filteredOrders: [],
    orderModal: false,
    orderDetails: {},
    partnerAssignModal: false,
    deliveryPartners: [],
    selectedDeliveryPartner:"",
    loading: false,
    updatedDate: null,
    pendingOrders: [],
    assignedOrders: [],
    completedOrders: [],
    newdeliveryPartners : []
};


const orderReducer = createSlice({
    name: "orderReducer",
    initialState: initialState,
    reducers: {
        updateOrders: (state, { type, payload }) => {
            console.log(payload)
            state.orders = payload
        },
        updateOrderModel: (state, { type, payload }) => {
            state.orderModal = payload
        },
        updateOrderDetails: (state, { type, payload }) => {
            state.orderDetails = payload
        },
        updatePartnerAssignModal: (state, { type, payload }) => {
            state.partnerAssignModal = payload
        },
        updateDeliveryPartners: (state, { type, payload }) => {
            state.deliveryPartners = payload
        },
        updateNewDeliveryPartners: (state, { type, payload }) => {
            state.newdeliveryPartners = payload
        },
        updateselectedDeliveryPartner: (state, { type, payload }) => {
            state.selectedDeliveryPartner = payload
        },
        updatefilteredOrders: (state, { type, payload }) => { 
            state.filteredOrders = payload
        },
        updateLoading: (state, { type, payload }) => {
            state.loading = payload
        },
        updateUpdatedDate: (state, { type, payload }) => {
            console.log(payload)
            state.updatedDate = payload
        },
        updatependingOrders: (state, {  payload }) => {
            state.pendingOrders = payload
        },
        updateassignedOrders: (state, { payload }) => {
            state.assignedOrders = payload
        },
        updatecompletedOrders: (state, {  payload }) => {
            state.completedOrders = payload
        },
        
    }
})

export const { updateOrders, updateOrderModel, updateOrderDetails, updatePartnerAssignModal, updateDeliveryPartners ,updateselectedDeliveryPartner ,updatefilteredOrders,updateNewDeliveryPartners ,updateLoading ,updateUpdatedDate ,updatependingOrders,
    updateassignedOrders,updatecompletedOrders } = orderReducer.actions



export default orderReducer.reducer;