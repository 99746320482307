import { Box, Button, InputBase, Modal, Typography, Input, IconButton, Tooltip, Select, MenuItem, getListSubheaderUtilityClass, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchOrders, updatesearchProduct } from "../../Redux/page";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { OrderListTable } from "../../sub_components/orders_table_dp";
import CustomAxios from "../../utils/CustomAxios";
import CustomAxiosDP from "../../utils/CustomAxiosDP";

import { updateDeliveryPartners, updateOrderDetails, updateOrderModel, updateOrders, updatePartnerAssignModal, updatefilteredOrders, updateselectedDeliveryPartner } from "../../Redux/orders";
import { OrderProductsTable } from "../../sub_components/ordered_products_table";
import { format, subDays, addDays } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { jwtDecode } from "jwt-decode";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import '../../App.css';
import { Deliverydetails, useDateformate, useFetchOrders, useGetOrders } from "../globalFunctions";
import { updateDelivery } from "../../Redux/deliveryUser";
import CircularProgress from '@mui/material/CircularProgress';

export function OrederManagement() {
    const dispatch = useDispatch()
    const toast = useRef(null);
    const actorders = useSelector(state => state.orders.orders);
    const [orders,setOrders] = useState([])
    const filteredOrders = useSelector(state => state.orders.filteredOrders);
    const orderModal = useSelector(state => state.orders.orderModal);
    const deliveryPartnerModal = useSelector(state => state.orders.partnerAssignModal);
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const authToken = localStorage.getItem('authtoken')
    const decodedToken = jwtDecode(authToken);
    const mobileno = decodedToken.mobileno;
    const partners = useSelector(state => state.orders.deliveryPartners);
    const [orderDetails,setOrdersDetails] = useState({})
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const selectedDeliveryPartner = useSelector(state => state.orders.selectedDeliveryPartner);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [loading, setLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);
    const [deliverypartner, setDeliverypartner]= useState()
    const today = dayjs()
    const GetOrders = useGetOrders()
    const fetchOrders = useFetchOrders()
    const formatDate = useDateformate()

    
    useEffect(()=>{
        const orders = actorders.filter(order=>{if (order.delivery_partner_id==deliverypartner?.id){
            return order
        }})
        setOrders(orders)

    },[actorders])

    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])

    useEffect(()=>{
        setDeliveryPartners(partners)
    },[partners])

    useEffect(()=>{
        fetchOrders(today, deliverypartner)
    },[orders])

        

    useEffect(()=>{
        const delivery_partner = deliveryPartners.find((partner)=>partner.mobileno === mobileno)
        console.log("41 -------------------------------- deliverypartner",mobileno,deliverypartner)
        setDeliverypartner(delivery_partner)
        dispatch(updateselectedDeliveryPartner(delivery_partner))

    },[deliveryPartners])


    
   
    const getUsers = async () => {
        const users = await CustomAxios.get(`get_users/`, {
        }).then(res => {
            console.log("--------->",res.data)
            let deliveryPartners = res.data.users.filter((user)=>{
                return user.role ==='delivery'
            })
            dispatch(updateDeliveryPartners(deliveryPartners))
        })

    }
    // const formatDate = (dateString) => {
    //     const dateObject = new Date(dateString);
    //     const day = String(dateObject.getDate()).padStart(2, '0');
    //     const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    //     const year = dateObject.getFullYear();
    //     return `${day}-${month}-${year}`;
    // };

    useEffect(() => {
        getUsers()
    }, [])

    

    const handleCloseOrderModal = () => {
        dispatch(updateOrderModel(false))
        dispatch(updateOrderDetails({}))
        dispatch(updateselectedDeliveryPartner(""))
    };
    const handleClosedeliveryPartnerModal = () => {
        dispatch(updateselectedDeliveryPartner(""))
        dispatch(updatePartnerAssignModal(false))
    };

    const delivery_update = async (id, partner, newStatus) => {
        // Use the already decoded token to get the user id
        const userId = decodedToken.id; // Extract user id from already decoded token
        
        console.log("Decoded user id:", userId);
    
        const delivery_partner = deliveryPartners.find((part) => part.id == partner);
        console.log("////////////////////////////////////", delivery_partner);
    
        // Determine the state based on the newStatus
        const state = newStatus === "delivery_partner_accepted" 
            ? "delivery_partner_accepted" 
            : newStatus === "declined" 
            ? "Cancelled" 
            : "Delivered";
    
        try {
            // Make the API request, passing the user id and other data as needed
            const response = await CustomAxiosDP.put(`delivery_update/${id}/${state}/`,
                {
                    userId: userId // Sending the userId in the body
                },

                
                {
                headers: {
                    'Authorization': `Bearer ${authToken}`, // Send the JWT in the headers
                },
                // data: {
                //     userId: userId // Send the user id as part of the request body
                // }
            });
    
            console.log("Response status:", response.status);
            console.log("Response data:", response.data.message);
        } catch (error) {
            console.error("Error updating delivery:", error);
        }
    };
    
    const handleStatusChange = async (id, newStatus,partner) => {


        try {
            if(newStatus=='accepted')setDeclineLoading(true)
            if(newStatus=='delivery_partner_accepted'||newStatus=='delivered')setLoading(true)
            await CustomAxios.put(`checkout/update/${id}/`, { status: newStatus,partnerId:partner.id })
               .then((res) => {
                console.log(res.status)
                if (res?.status == 200) {
                    console.log("hereee")
                    if(newStatus =="delivery_partner_accepted"){
                        delivery_update(id, partner, newStatus)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Delivery Partner Assigned Successfully`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    if( newStatus =="delivered"){
                        delivery_update(id, partner, newStatus)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Order Delivered sucessfully`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    if( newStatus =="accepted"){
                        delivery_update(id, partner, newStatus)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Order Declined`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    // else{
                    //     toast.current.show({ severity: 'success', summary: 'Success', detail: `Order ${res.data.order.status} Successfully`, life: 3000 })
                    //     // dispatch(updateOrderModel(false))
                    //     dispatch(updateOrderDetails(res.data.order))
                    //     // console.log("we are here ")
                    //     // const ws = new WebSocket('ws://192.168.209.15:8000/aiq65/ws/orders/');
                    //     // ws.onopen = () => {
                    //     //   ws.send(JSON.stringify({ newStatus }));
                    //     //   ws.close();
                    //     // };
                    // }
                    setLoading(false);
                    setDeclineLoading(false)
                    GetOrders()

                }
            })

        } catch (err) {
            setLoading(false);
            setDeclineLoading(false)
        }
    };
    // const fetchOrders = (date) => {
    //     console.log(date)
    //     const formattedDate = date.format('DD-MM-YYYY');
    //     console.log(`Fetching orders for ${formattedDate}`);
    //     const filteredOrders = orders?.filter(order => {
    //         const orderDate = dayjs(order.created_on).format('DD-MM-YYYY');
    //         console.log(orderDate, formattedDate);
    //         return orderDate === formattedDate; 
    //     });
    //     console.log(filteredOrders);
    //       dispatch(updatefilteredOrders(filteredOrders));
    //       console.log(filteredOrders)
    //   };
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        fetchOrders(newDate,deliverypartner);
      };
    
      const handlePreviousDay = () => {
        const newDate = selectedDate.subtract(1, 'day');
        setSelectedDate(newDate);
        fetchOrders(newDate,deliverypartner);
      };
    
      const handleNextDay = () => {
        const newDate = selectedDate.add(1, 'day');
        setSelectedDate(newDate);
        fetchOrders(newDate, deliverypartner);
      };

      const viewAllOrders = () => {
        dispatch(updatefilteredOrders(orders));
      };
      const [pending,Setpending] = useState(0)
      const [assigned,SetAssigned] = useState(0)
      const [completed,SetCompleted] = useState(0)
      useEffect(()=>{
        if(filteredOrders.length>0){
            const pendingOrders = filteredOrders.filter((item) => item.status === "pending") 
            const assignedOrders = filteredOrders.filter((item) => item.status === "delivery_partner_assigned") 
            const completedOrders = filteredOrders.filter((item) => item.status === "delivered") 
            Setpending(pendingOrders.length)
            SetAssigned(assignedOrders.length)
            SetCompleted(completedOrders.length)
        }else{
            Setpending(0)
            SetAssigned(0)
            SetCompleted(0)
        }
      },[filteredOrders])
    console.log(today)
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" ,padding:"30px"}}>
            <Toast ref={toast} />
            <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition="Bounce" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                     <Typography sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "24px" }}>Order Management</Typography>
                     <Box sx={{display:"flex",gap:"20px",alignItems:"center"}}>
                     {selectedDate.format('DD-MM-YYYY') === today.format('DD-MM-YYYY') &&<Typography sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px",textAlign:"center" }}> Today </Typography>}
                       <Stack direction="row" spacing={2} alignItems="center">
                            <IconButton sx={{backgroundColor:"secondary.border"}} onClick={handlePreviousDay}>
                            <ChevronLeftIcon/>
                            </IconButton>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                                className="pan-datepicker background"
                                renderInput={(params) => <TextField {...params} />}
                                sx={{
                                    "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root": {
                                        height: '32px',
                                        padding: '0 8px',
                                        border: "none",
                                        width: "200px",
                                        backgroundColor: "#FFF",
                                        zIndex: 2
                                    },
                                    "&.MuiPaper-root-MuiPickersPopper-paper": {
                                        backgroundColor: "#FFF"
                                    },
                                    "& .MuiPaper-root": {
                                        backgroundColor: "#FFF", // Background of the dropdown
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional: add shadow
                                        borderRadius: '8px', // Optional: rounded corners
                                    },
                                    
                                }}
                            />
                            </LocalizationProvider>
                            <IconButton sx={{backgroundColor:"secondary.border"}}  onClick={handleNextDay}>
                            <ChevronRightIcon/>
                            </IconButton>
                        </Stack>
                     </Box>
                      
                </Box>
                <Box sx={{display:"flex",gap:"20px",marginBottom:"30px"}}>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#FDE9F2",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #ED1E7933",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #F8A5C9"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{filteredOrders.length}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Total Orders</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#E6F1F8",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #0071BC33",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #80B8DE"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{pending}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>New Orders</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#FFF7EB",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #FBB03B33",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #FDD089"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{assigned}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Orders in Progress</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#F0FDF4",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #4ADE8033",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #86EFAC"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{completed}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Completed Orders</Typography>
                        </Box>
                     </Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}>Order Lists</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ position: "relative" }}>
                    <InputBase sx={{ backgroundColor: "white", height: "32px", width: "100%", maxWidth: "268px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px 5px 40px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} onChange={(e) => dispatch(updateSearchOrders(e.target.value))} placeholder="Search order Id" />
                    <SearchIcon sx={{ position: "absolute", left: "12px", top: "5px", width: "20px", height: "20px" }} />
                </Box>
                <Button variant="contained" sx={{ textTransform: "none" }} onClick={()=>viewAllOrders()}> View all</Button>
            </Box>
            <OrderListTable />
            <Modal
                open={orderModal}
                onClose={handleCloseOrderModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: "1000px", height: "550px", backgroundColor: 'white', }}>

                    <Box sx={{ height: "46px important", padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}>Order ID: {orderDetails.order_id}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", padding: "24px", gap: "20px" }}>
                        <Box sx={{ display: "flex", gap: "24px", width: "100%" }}>
                            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width:"350px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Customer Details</Typography>
                                <Box>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Name: {orderDetails?.delivery_address?.name}</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Door / Flat No: {orderDetails?.delivery_address?.door_flat_no}</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Address: {orderDetails?.delivery_address?.address}</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Mobile No: {orderDetails?.delivery_address?.mobileNo}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column",  width:"200px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Order on</Typography>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{formatDate(orderDetails.created_on)}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: "200px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Order Status</Typography>
                                 <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Order {orderDetails.status}</Typography>
                                {/* <Select
                                    labelId={`status-label-${orderDetails.id}`}
                                    value={orderDetails.status}
                                    onChange={(e) => handleStatusChange(orderDetails.id, e.target.value, deliverypartner)}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    sx={{ textAlign:"left",width: "200px", height: "32px", backgrkoundColor: orderDetails.status == "declined"?"#FFF1F2":orderDetails.status == "delivered" ? "#F6FFED" : orderDetails.status == "pending"?"#E6F1F8":orderDetails.status == "delivery_partner_assigned"||orderDetails.status == "delivery_partner_accepted"?"#FFF7EB":"#F9FAFB",
                                     color: orderDetails.status == "declined"?"#E11D48":orderDetails.status == "delivered" ? "#52C41A" :orderDetails.status == "pending"?"#0071BC":orderDetails.status == "delivery_partner_assigned"||orderDetails.status == "delivery_partner_accepted"?"#FBB03B": "#6B7280",
                                     borderColor: orderDetails.status == "declined"?"#E11D48":orderDetails.status == "delivered" ? "#F6FFED" : orderDetails.status == "pending"?"#E6F1F8":orderDetails.status == "delivery_partner_assigned"||orderDetails.status == "delivery_partner_accepted"?"#FBB03B":"#F9FAFB",border:"1 px solid" }}
                                >
                                    <MenuItem value="pending" >Pending</MenuItem>
                                    <MenuItem value="accepted"  >Accepted</MenuItem>
                                    <MenuItem value="declined"  >Declined</MenuItem>
                                    <MenuItem value="delivery_partner_assigned" >Assigned to you</MenuItem>
                                    <MenuItem value="delivery_partner_accepted" >Accepted by you</MenuItem>
                                    <MenuItem value="delivered" >Delivered</MenuItem>
                                </Select> */}
                            </Box>
                            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: "200px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Delivery Partner</Typography>
                                <Button variant="contained" sx={{ textTransform: "none", height: "32px", width: "200px" }} disabled={orderDetails.status==="delivered"?true:false} onClick={() => handleStatusChange(orderDetails.id, orderDetails.status==="delivery_partner_assigned"?'delivery_partner_accepted':'delivered',deliverypartner)} > {orderDetails.status==="delivery_partner_assigned" ? loading ?  <CircularProgress size="18px" sx={{color:"white"}}/>:'Accept' :orderDetails.status==="delivery_partner_accepted"?loading ?  <CircularProgress size="18px" sx={{color:"white"}}/>:"Deliver": 'Delivered'}</Button>
                            </Box>

                        </Box>
                        <OrderProductsTable />
                    </Box>
                    <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", position: "absolute", bottom: 0, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
                            <Button variant="outlined" sx={{ textTransform: "none", color: "black", backgroundColor: "transparent", height: "32px", width: "100%", border: "none" }} onClick={() => dispatch(updateOrderModel(false))} > close</Button>
                            { <Box sx={{ display: "flex", gap: "20px" }}><Button variant="outlined" disabled={orderDetails.status == 'delivered' ? true:false}  sx={{ textTransform: "none", color: "black", backgroundColor: "white", height: "32px", width: "200px" }} onClick={() => handleStatusChange(orderDetails.id, 'accepted', deliverypartner)}> {orderDetails.status == 'declined' ? 'Order Declined' : declineLoading ?  <CircularProgress size="18px" sx={{color:"primary.button"}}/>:'Decline'}</Button>
                            <Button variant="contained" sx={{ textTransform: "none", height: "32px", width: "200px",backgroundColor:orderDetails.status == 'delivered'? "#B7EB8F":"primary.button"}} onClick={() => window.open(`https://www.google.com/maps?q=${orderDetails.delivery_address.latitude},${orderDetails.delivery_address.longitude}`, "_blank", "width=600,height=400,top=100,left=100,noopener,noreferrer")} > {orderDetails.status == 'accepted' ||orderDetails.status == 'delivery_partner_assigned'||orderDetails.status == 'delivery_partner_accepted' ? 'Open Google Map' : orderDetails.status == 'delivered' ? <Button sx={{ textTransform: "none", color: "#52C41A", height: "32px", border: "none" }} >Order completed</Button> :'Accept'}</Button></Box>}
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={deliveryPartnerModal}
                onClose={handleClosedeliveryPartnerModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1300 }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: "294px", height: "200px", backgroundColor: 'white', }}>

                    <Box sx={{ height: "46px important", padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "14", fontWeight: "500", lineHeight: "22px" }}>ASSIGN DELIVERY PARTNER</Typography>
                    </Box>
                 <Box sx={{display:"flex",padding:"20px",flexDirection:"column"}}>
                 <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Search Partner</Typography>
                 <Select
                                    labelId={`status-label-${orderDetails.id}`}
                                    value={selectedDeliveryPartner}
                                    onChange={(e) => dispatch(updateselectedDeliveryPartner(e.target.value))}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                backgroundColor: 'white', 
                                            },
                                        },
                                    }}
                                    sx={{ width: "100%", height: "32px",}}
                                      defaultValue=""

                                >
                                    {deliveryPartners.length>0?deliveryPartners?.map((user)=>(
                                    <MenuItem key={user.id} sx={{background:"white",'&:hover': {
                                        backgroundColor: 'white', 
                                      },}} value={user.id} >{user.first_name+" "+user.last_name}</MenuItem>
                                    )):
                                    <MenuItem  >No partners Available</MenuItem>
                                    }
                                </Select>
                 </Box>
                    <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", position: "absolute", bottom: 0, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
                            <Button variant="outlined" sx={{ textTransform: "none", color: "black", backgroundColor: "transparent", height: "32px", width: "100%", border: "none" }} onClick={handleClosedeliveryPartnerModal}> close</Button>
                            <Button variant="contained" sx={{ textTransform: "none",  height: "32px", width: "100%", border: "none" }} onClick={() => handleStatusChange(orderDetails.id, 'delivery_partner_assigned',selectedDeliveryPartner)} > Assign</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}