import { useDispatch, useSelector } from "react-redux"
import CustomAxios from "../utils/CustomAxios"
import { updateLoading, updateOrderDetails, updateOrderModel, updateOrders, updatePartnerAssignModal, updateUpdatedDate, updateassignedOrders, updatecompletedOrders, updatefilteredOrders, updatependingOrders, updateselectedDeliveryPartner } from "../Redux/orders"
import { updateLoadingProducts, updateProducts } from "../Redux/products";
import { updateInventory, updateInventoryDetailsLoading, updateInventoryDetailsModal, updateInventoryLoading, updateInventoryModal, updateInventoryform, updateallInventryDetails } from "../Redux/inventory";
import dayjs from "dayjs";
import { Box, Button, MenuItem, Modal, Select, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Toast } from 'primereact/toast';
import { OrderProductsTable } from "../sub_components/ordered_products_table";
import CustomAxiosDP from "../utils/CustomAxiosDP";
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export const useGetOrders = () => { 
    const dispatch = useDispatch();
    const today = dayjs()
    const formatdate = useDateformate()
  
    const getOrders = async () => {
      dispatch(updateLoading(true))
      try {
        const response = await CustomAxios.get(`get_all_orders/`);
  
        // Filter orders based on payment_status === true
        const validOrders = response.data.filter((order) => order.payment_status === true)
  
        dispatch(updateOrders(validOrders))
        dispatch(updateLoading(false))
  
        if (validOrders.length > 0) {
          
          const pendingOrders = validOrders.filter(
            (item) =>
              item.status === "pending" &&
              formatdate(item.created_on) === today.format("DD-MM-YYYY")
          )
          const assignedOrders = validOrders.filter((item) => item.status === "delivery_partner_assigned")
          const completedOrders = validOrders.filter((item) => item.status === "delivered")
  
          
          dispatch(updatependingOrders(pendingOrders))
          dispatch(updateassignedOrders(assignedOrders))
          dispatch(updatecompletedOrders(completedOrders))
        } else {
          
          dispatch(updatependingOrders(0))
          dispatch(updateassignedOrders(0))
          dispatch(updatecompletedOrders(0))
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        dispatch(updateLoading(false))
      }
    };
  
    return getOrders;
  };
  



export const useGetProducts = () => {
    const dispatch = useDispatch();
    dispatch(updateLoadingProducts(true))
    const getDetails = async () => {
        try {
            const response = await CustomAxios.get(`products/`, {
            }).then(res => {
                const options = res?.data?.map((item) => ({
                    name: item.item_name,
                    value: item.id,
                    image: item.image_urls[0],
                    ...item
                }));
                dispatch(updateProducts(options))
                dispatch(updateLoadingProducts(false))
            })
        }
        catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateLoadingProducts(false))
        }

    }
    return getDetails;
};


export const useGetInventory = () => {
    const dispatch = useDispatch();
    dispatch(updateInventoryLoading(true))

    const getInventerory = async () => {
        try {
            const inventory = await CustomAxios.get(`inventory/view`, {
            }).then(res => {
                console.log(res)
                const options = res?.data?.map((item) => ({
                    name: item.place,
                    value: item.id,
                    supervisors: item.supervisors,
                }));
                dispatch(updateInventoryform({
                    place: '',
                    supervisor_ids: []
                },))
                dispatch(updateInventoryModal(false))
                options && dispatch(updateInventory(options))
            })
        }
        catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateInventoryLoading(false))
        }
    }

    return getInventerory;
};

export const useGetInventoryDetails = () => {
    const dispatch = useDispatch();
    dispatch(updateInventoryDetailsLoading(true))

    const getInventeroryDetails = async () => {
        try {
            const inventory = await CustomAxios.get(`inventory/details/view`, {
            }).then(res => {
                const options = res?.data?.map((item) => ({
                    ...item,
                    details_id: item.details,
                }));
                dispatch(updateallInventryDetails(options))
                dispatch(updateInventoryDetailsModal(false))
            })
        }
        catch (error) {
            console.error("Error fetching orders:", error);
            dispatch(updateInventoryDetailsLoading(false))
        }
    }

    return getInventeroryDetails;
};


export const useFetchOrders = () => {
    const dispatch = useDispatch();
    const actorders = useSelector(state => state.orders.orders);
    const formatDate = useDateformate()

    const fetchOrders =async (date, deliverypartner) => {
        console.log(formatDate(date))
        let filteredOrders = []
        let orders = actorders
        // if(deliverypartner){
        //    orders = actorders.filter(order=>{if (order.delivery_partner_id==deliverypartner?.id){
        //         return order
        //     }})
        // }
        const response = await CustomAxios.get(`get_orders_date/${formatDate(date)}/`,)
            const fOrders = response.data
            if(deliverypartner){
                filteredOrders = fOrders.filter(order=>{if (order.delivery_partner_id==deliverypartner?.id){
                     return order
                 }})
             }
            else{
                filteredOrders = fOrders
            }
            const validOrders = filteredOrders.filter((order) => order.payment_status === true)
        // if(typeof date === 'string'){
        //     const formattedDate = formatDate(date)
        //     console.log(`Fetching orders for ${formattedDate}`);
        //     const response = await CustomAxios.get('get_orders_date/',{"date":formattedDate})
        //     filteredOrders
        //      filteredOrders = orders.filter(order => {
        //         const orderDate = dayjs(order.created_on).format('DD-MM-YYYY');
        //         console.log(orderDate, formattedDate);
        //         return orderDate === formattedDate;
        //     });
        // }else{
        //     const formattedDate = date.format('DD-MM-YYYY');
        // console.log(`Fetching orders for ${formattedDate}`);
        //  filteredOrders = orders.filter(order => {
        //     const orderDate = dayjs(order.created_on).format('DD-MM-YYYY');
        //     console.log(orderDate, formattedDate);
        //     return orderDate === formattedDate; 
        // });
        // }
        
        
        console.log(validOrders);
          dispatch(updatefilteredOrders(validOrders));
          console.log(validOrders)
      };

    return fetchOrders;
}; 

export const useDateformate = () => {

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return formatDate;
};

export const OrderModal = () => {
    const dispatch = useDispatch();
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const [loading, setLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);
    const partners = useSelector(state => state.orders.deliveryPartners);
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const orderModal = useSelector(state => state.orders.orderModal);
    const GetOrders = useGetOrders()
    const formatDate = useDateformate()
    const toast = useRef(null);
    const deliveryMode = 1
    const [orderDetails,setOrdersDetails] = useState({})
    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])
    useEffect(()=>{
        setDeliveryPartners(partners)
    },[partners])
    const handleCloseOrderModal = () => {
        dispatch(updateOrderModel(false))
        dispatch(updateOrderDetails({}))
        dispatch(updateselectedDeliveryPartner(""))
    };
    const handleStatusChange = async (id, newStatus,partner) => {
        try {
            if(newStatus=='accepted')setLoading(true)
            if(newStatus=='declined')setDeclineLoading(true)
            await CustomAxios.put(`checkout/update/${id}/`, { status: newStatus,partnerId:partner }).then((res) => {
                console.log(res.status)
                if (res?.status == 200) {
                    console.log("hereee")
                    if(newStatus =="delivery_partner_assigned"){
                        console.log("we were here first")
                        delivery_update(id, partner)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Delivery Partner Assigned Successfully`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    else{
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Order ${res.data.order.status} Successfully`, life: 3000 })
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    
                    GetOrders()
                    dispatch(updateUpdatedDate(partner.created_on))
                    setLoading(false)
                    setDeclineLoading(false)
                }
            })

        } catch (err) {
            console.error("Error updating status:", err);
            setLoading(false); // End loading
            setDeclineLoading(false)
        } finally {
            setLoading(false); // End loading
            setDeclineLoading(false)
        }
    };
    const delivery_update = async(id, partner) => {
        console.log("we are here", typeof(partner), deliveryPartners)
        const delivery_partner = deliveryPartners.find((part)=>part.id==partner)
        console.log("////////////////////////////////////",delivery_partner)
        const response = await CustomAxiosDP.post('delivery_create/',{mobileno:delivery_partner.mobileno, delivery_mode:deliveryMode, delivery_status:"Pending", user_checkout_id:id}).then((response)=>{
            console.log(response.status)
            console.log(response.data.message)
        })
    }
    console.log(orderModal)

    const confirmDecline = (item,which) => {
        confirmDialog({
          message: (
            <Box sx={{ display: "flex", gap: "10px",flexDirection:"column" }}>
              {which=="decline"?<><Typography sx={{fontSize:"16px",fontWeight:500}}>Do you want to cancel this Order </Typography>
              <Typography sx={{fontSize:"14px",fontWeight:400}}>The order {item.order_id} will be canceled, and the refund will be initiated. </Typography></>:
              <><Typography sx={{fontSize:"14px",fontWeight:400}}>Are You Sure </Typography>
              <Typography sx={{fontSize:"14px",fontWeight:400}}>Do you want to initiate refund for this Order. </Typography>
              </>
              }
            </Box>
          ),
          header: which=="decline"?'Cancel Order':"Refund",
          defaultFocus: 'reject',
          acceptClassName: 'p-button-danger',
          accept: () => accept(item),
        });
      };

      const accept = async (item) => {
        try {
            const response = await CustomAxios.post(`/cancel_order/`,{
              user_id:item.user,
              order_id:item.order_id
            }).then((res)=>{
              if (res.data.message=="refund initiated successfully"){
                console.log(res.data)
                dispatch(updateOrderDetails(res.data.order))
                GetOrders()
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Order cancelled Successfully', life: 3000 });
              }
            })
          } catch (error) {
            console.error("Error fetching order details:", error);
          }
      }
    return (
        <Box>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Modal
                open={orderModal}
                onClose={handleCloseOrderModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: "1000px", height: "550px", backgroundColor: 'white', }}>

                    <Box sx={{ height: "46px important", padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}>Order ID: {orderDetails.order_id}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", padding: "24px", gap: "20px" }}>
                        <Box sx={{ display: "flex", gap: "24px", width: "100%" }}>
                            {orderDetails.delivery_mode===1?<Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width:"350px"  }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px"}}>Customer Details</Typography>
                                <Box>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{orderDetails?.delivery_address?.door_flat_no} ,{orderDetails?.delivery_address?.address}</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{orderDetails?.delivery_address?.pin}</Typography>
                                </Box>
                            </Box>:<Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width:"350px"  }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px"}}>Customer Details</Typography>
                                <Box>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Customer Name: {orderDetails.user_details?.first_name} {orderDetails.user_details?.last_name}</Typography>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Mobile No: {orderDetails.user_details?.mobileno}</Typography>
                                </Box>
                            </Box>}
                            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column",  width:"200px"  }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Order on</Typography>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{formatDate(orderDetails.created_on)}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: "200px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>Order Status</Typography>
                                 <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>{orderDetails.status}</Typography>
                                {/* <Select
                                    labelId={`status-label-${orderDetails.id}`}
                                    value={orderDetails.status}
                                    onChange={(e) => handleStatusChange(orderDetails.id, e.target.value, orderDetails)}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                backgroundColor: 'white',
                                            },
                                        },
                                    }}
                                    sx={{ textAlign:"left",width: "200px", height: "32px", backgroundColor: orderDetails.status == "declined"?"#FFF1F2":orderDetails.status == "delivered" ? "#F6FFED" : orderDetails.status == "pending"?"#E6F1F8":orderDetails.status == "delivery_partner_assigned"?"#FFF7EB":"#F9FAFB",
                                     color: orderDetails.status == "declined"?"#E11D48":orderDetails.status == "delivered" ? "#52C41A" :orderDetails.status == "pending"?"#0071BC":orderDetails.status == "delivery_partner_assigned"?"#FBB03B": "#6B7280",
                                     borderColor: orderDetails.status == "declined"?"#E11D48":orderDetails.status == "delivered" ? "#F6FFED" : orderDetails.status == "pending"?"#E6F1F8":orderDetails.status == "delivery_partner_assigned"?"#FBB03B":"#F9FAFB",border:"1 px solid" }}
                                >
                                    <MenuItem value="pending" >Pending</MenuItem>
                                    <MenuItem value="accepted"  >Accepted</MenuItem>
                                    <MenuItem value="declined"  >Declined</MenuItem>
                                    <MenuItem value="delivery_partner_assigned" >Delivery partner assigned</MenuItem>
                                    <MenuItem value="delivery_partner_accepted" >Delivery partner accepted</MenuItem>
                                    <MenuItem value="delivered" >Delivered</MenuItem>
                                </Select> */}
                            </Box>
                            {orderDetails.delivery_mode==1 && <Box sx={{ display: "flex", gap: "4px", flexDirection: "column", width: "200px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "22px" }}>{orderDetails.delivery_mode===1?"Delivery Partner":"Customer"}</Typography>
                                <Button variant="contained" disabled={orderDetails.status === 'declined'|| orderDetails.status === 'delivered'|| orderDetails.status === 'cancelled'} sx={{ textTransform: "none", height: "32px", width: "200px" }} onClick={() => dispatch(updatePartnerAssignModal(true))} > {orderDetails.delivery_partner_id==0||orderDetails.delivery_partner_id==null ? 'Assign' : 'Assigned'}</Button>
                            </Box>}

                        </Box>
                        <OrderProductsTable />
                    </Box>
                    <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", position: "absolute", bottom: 0, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
                     

                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", justifyContent: "flex-end", padding: "12px 24px", gap: "20px" }}>
                    <Button variant="outlined" sx={{ textTransform: "none", color: "black" }} onClick={() => dispatch(updateOrderModel(false))}>
                    Close
                    </Button>
                    {(orderDetails.status === 'cancelled'&& orderDetails.cancel_request==true)&&<Button variant="contained"  sx={{ textTransform: "none", color: "white", backgroundColor: "primary.button", width: "200px" }} onClick={() => confirmDecline(orderDetails,"refund")} >
                     Refund   
                    </Button>}
                    {orderDetails.status === 'cancelled'?<Button variant="contained" disabled={orderDetails.status === 'cancelled'} sx={{ textTransform: "none", color: "black", backgroundColor: "white", width: "200px" }} >
                     Order Cancelled   
                    </Button> :
                    <> <Button variant="outlined" disabled={orderDetails.status === 'declined'|| orderDetails.status === 'delivered'} sx={{ textTransform: "none", color: "black", backgroundColor: "white", width: "200px" }} onClick={() => confirmDecline(orderDetails,"decline")}>
                    {orderDetails.status == 'declined' ? 'Order Declined' : declineLoading ?  <CircularProgress size="18px" sx={{color:"primary.button"}}/>:'Decline'}
                    </Button>
                    {orderDetails.delivery_mode==1?<Button disabled={orderDetails.status == 'accepted' ||orderDetails.status == 'delivery_partner_accepted'|| orderDetails.status == 'delivered' ?true:false} variant="contained" sx={{ textTransform: "none", height: "32px", width: "200px",backgroundColor:orderDetails.status == 'delivered'? "#F6FFED":"primary.button"}} onClick={() => orderDetails.status == 'delivery_partner_assigned'?handleStatusChange(orderDetails.id, 'delivered'):handleStatusChange(orderDetails.id, 'accepted')} > {orderDetails.status == 'accepted' ||orderDetails.status == 'delivery_partner_accepted' ? 'Order Accepted' : orderDetails.status == 'delivered' ? <Button sx={{ textTransform: "none", color: "black", height: "32px", border: "none" }} >Order completed</Button>:orderDetails.status == 'delivery_partner_assigned' ? <Tooltip placement="top" title="Update the order status to 'Delivered' if the status update was missed by the delivery partner." arrow><Button sx={{ textTransform: "none", color: "white", height: "32px", border: "none" }} >Delivered ?</Button> </Tooltip>:'Accept'}</Button>
                                :<Button disabled={orderDetails.status == 'picked' ?true:false} variant="contained" sx={{ textTransform: "none", height: "32px", width: "200px",backgroundColor:orderDetails.status == 'picked'? "#F6FFED":"primary.button"}} onClick={() => orderDetails.status == 'accepted'?handleStatusChange(orderDetails.id, 'delivered'):handleStatusChange(orderDetails.id, 'accepted')} > {orderDetails.status == 'delivered' ? <Button sx={{ textTransform: "none", color: "black", height: "32px", border: "none" }} >Order completed</Button>:orderDetails.status == 'accepted' ? <Tooltip placement="top" title="Update the order status to 'Delivered' if the status update was missed by the delivery partner." arrow><Button sx={{ textTransform: "none", color: "white", height: "32px", border: "none" }} >Picked Up ?</Button> </Tooltip>:'Accept'}</Button>}
                    </>
                   
                    }
                    </Box>
                    </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
            
    )
}

export const OrderModalDelivery = () => {
    const dispatch = useDispatch();
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const [loading, setLoading] = useState(false);
    const partners = useSelector(state => state.orders.deliveryPartners);
    const newpartners = useSelector(state => state.orders.newdeliveryPartners);
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const orderModal = useSelector(state => state.orders.orderModal);
    const GetOrders = useGetOrders()
    const deliveryPartnerModal = useSelector(state => state.orders.partnerAssignModal);
    const selectedDeliveryPartner = useSelector(state => state.orders.selectedDeliveryPartner);
    const toast = useRef(null);
    const deliveryMode = 1
    const [orderDetails,setOrdersDetails] = useState({})
    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])
    useEffect(()=>{
        setDeliveryPartners(newpartners)
    },[newpartners])
    const handleClosedeliveryPartnerModal = () => {
        dispatch(updateselectedDeliveryPartner(""))
        dispatch(updatePartnerAssignModal(false))
    };


    console.log("delivery details ------------------", partners)

    const handleStatusChange = async (id, newStatus,partner) => {
        try {
            setLoading(true);
            await CustomAxios.put(`checkout/update/${id}/`, { status: newStatus,partnerId:partner }).then((res) => {
                console.log(res.status)
                if (res?.status == 200) {
                    console.log("hereee")
                    if(newStatus =="delivery_partner_assigned"){
                        console.log("we were here first")
                        delivery_update(id, partner)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Delivery Partner Assigned Successfully`, life: 3000 })
                        dispatch(updateselectedDeliveryPartner(res.data.order.delivery_partner_id))
                        dispatch(updatePartnerAssignModal(false))
                        // dispatch(updateOrderModel(false))
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    else{
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Order ${res.data.order.status} Successfully`, life: 3000 })
                        dispatch(updateOrderDetails(res.data.order))
                    }
                    
                    GetOrders()
                    dispatch(updateUpdatedDate(partner.created_on))
                    setLoading(false);
                }
            })

        } catch (err) {
            console.error("Error updating status:", err);
            setLoading(false);
        } finally {
            setLoading(false); // End loading
        }
    };
    const delivery_update = async(id, partner) => {
        console.log("we are here", typeof(partner), deliveryPartners)
        const delivery_partner = deliveryPartners.find((part)=>part.id==partner)
        console.log("////////////////////////////////////",delivery_partner)
        const response = await CustomAxiosDP.post('delivery_create/',{mobileno:delivery_partner.mobileno, delivery_mode:deliveryMode, delivery_status:"Pending", user_checkout_id:id}).then((response)=>{
            console.log(response.status)
            console.log(response.data.message)
        })
    }
    console.log("delivey partneressssssssssssssssssss ---------------------", newpartners)
    return (
        <Box>
            <Toast ref={toast} />
            <Modal
                open={deliveryPartnerModal}
                onClose={handleClosedeliveryPartnerModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1300 }}
            >
                <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', md: '294px' },
                    height: { xs: 'auto', md: '200px' },
                    backgroundColor: 'white',
                    borderRadius: 2,
                    paddingBottom: 2,
                }}
                >
                <Box sx={{ padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>ASSIGN DELIVERY PARTNER</Typography>
                </Box>
                <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>Search Partner</Typography>
                    <Select
                    labelId={`status-label-${orderDetails.id}`}
                    value={selectedDeliveryPartner}
                    onChange={(e) => dispatch(updateselectedDeliveryPartner(e.target.value))}
                    MenuProps={{
                        PaperProps: { style: { backgroundColor: 'white' } },
                    }}
                    sx={{ width: "100%", height: "32px" }}
                    defaultValue=""
                    >
                    {deliveryPartners.length > 0 ? deliveryPartners.map(user => (
                        <MenuItem key={user.id} sx={{ background: "white" }} value={user.id}>
                        {user.first_name + " " + user.last_name}
                        </MenuItem>
                    )) : <MenuItem>No partners Available</MenuItem>}
                    </Select>
                </Box>
                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", justifyContent: "flex-end", padding: "12px 24px", gap: "20px" }}>
                    <Button variant="outlined" sx={{ textTransform: "none", color: "black" }} onClick={handleClosedeliveryPartnerModal}>Close</Button>
                    <Button variant="contained" disabled={selectedDeliveryPartner === ""} sx={{ textTransform: "none" }} onClick={() => handleStatusChange(orderDetails.id, 'delivery_partner_assigned', selectedDeliveryPartner)}>
                    {loading ?  <CircularProgress size="18px" sx={{color:"white"}}/>: 'Assign'}
                    </Button>
                </Box>
                </Box>
            </Modal>
        </Box>
            
    )
};
