import { Box, Button, InputBase, Modal, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { InventoryDetailsTable } from "../../sub_components/inventoryDetails_table";
import { useDispatch, useSelector } from "react-redux";
import { updateInventory, updateInventoryDetail, updateInventoryDetailsModal, updateInventrySupervisors, updateInventryHubsupervisors, updateallInventryDetails, updateinventoryDetailsEdit, updateInventoryModal, updateinventoryEdit, updateInventoryform } from "../../Redux/inventory";
import { useEffect, useState } from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { updateProducts } from "../../Redux/products";
import { MultiSelect } from 'primereact/multiselect';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { InventoryTable } from "../../sub_components/inventory_table";
import CustomAxios from "../../utils/CustomAxios";
import * as Yup from "yup";
import { useGetInventory, useGetInventoryDetails, useGetProducts } from "../globalFunctions";
import { updateUpdatedDate } from "../../Redux/orders";
import CircularProgress from '@mui/material/CircularProgress';

export function Inventory() {
    const toast = useRef(null);
    const inventoryDetailsModal = useSelector(state => state.inventory.inventoryDetailsModal);
    const inventory = useSelector(state => state.inventory.inventory);
    const products = useSelector(state => state.products.products);
    const inventoryDetails = useSelector(state => state.inventory.inventory_detail)
    const inventoryForm = useSelector(state => state.inventory.inventoryForm)
    const authToken = localStorage.getItem('authtoken');
    const [formData, setFormData] = useState(inventoryDetails);
    const [inventoryform, setInventoryForm] = useState(inventoryForm);
    const supervisors = useSelector(state => state.inventory.supervisors)
    const Hubsupervisors = useSelector(state => state.inventory.hubsupervisors)
    const InventoryEdit = useSelector(state => state.inventory.inventoryEdit)
    const [loading, setLoading] = useState(false)
    const inventoryDetailsEdit = useSelector(state => state.inventory.inventoryDetailsEdit)
    const [errors, setErrors] = useState({});
    const GetProducts = useGetProducts()
    const getInventeroryDetails = useGetInventoryDetails()
    const getInventerory = useGetInventory()

    const inventoryModal = useSelector(state => state.inventory.modal);
    useEffect(() => {
        //console.log(inventoryForm)
        if (inventoryDetails) {
            setFormData(inventoryDetails)
        }
        if (inventoryForm) {
            setInventoryForm(inventoryForm)
        }
        dispatch(updateUpdatedDate(null))
    }, [inventoryDetails, inventoryForm])
    const dispatch = useDispatch()
    const handleOpen = () => {
        dispatch(updateInventoryDetail({
            product_id: '',
            total_units: '',
            units:"",
            available_units: '',
            place: '',
            supervisor_ids: [
                0
            ],
            details_id: ''
        }));
        dispatch(updateinventoryDetailsEdit(false));
        dispatch(updateInventryHubsupervisors([]))
        dispatch(updateInventoryDetailsModal(true))
        setErrors({})
    };
    const handleClose = () => {
        dispatch(updateInventoryDetail({
            product_id: '',
        total_units: '',
        units:"",
        available_units: '',
        place: '',
        supervisor_ids: [
            0
        ],
        details_id: ''
        }));
        dispatch(updateinventoryDetailsEdit(false));
        dispatch(updateInventryHubsupervisors([]))
        dispatch(updateInventoryDetailsModal(false));
        setErrors({})
    };
    const handleOpenInventoryModal = () => {
        dispatch(updateInventoryform({
            place: '',
            supervisor_ids: [],
        }));
        dispatch(updateinventoryEdit(false));
        dispatch(updateInventoryModal(true))
        setErrors({})

    };
    const handleCloseInventoryModal = () => {
        dispatch(updateInventoryform({
            place: '',
            supervisor_ids: [],
        }));
        dispatch(updateinventoryEdit(false));
        dispatch(updateInventoryModal(false))
        setErrors({})

    };

    const handleChange = (e) => {
        //console.log(e)
        const { name, value, label } = e.target;
        const filtered = inventory?.filter((item) => item.value == value)
        if (name == 'details_id') {
            const commonNames = supervisors
                .filter(obj1 => filtered[0].supervisors.some(obj2 => obj1.value === obj2))
                .map(obj => obj.name);
            //console.log(commonNames);
            dispatch(updateInventryHubsupervisors(commonNames))
            setFormData({
                ...formData,
                place: filtered[0].name,
                [name]: value,
                supervisor_ids: filtered[0].supervisors,
                // ...filtered[0]
            });
            
        } else {
            setFormData({
                ...formData,
                [name]: value,
                ...filtered[0]
            });

            // if (name === 'total_units' || name === 'units') {
            //     calculateTotalUnits({
            //       ...formData,
            //       [name]: value, 
            //     });
            //   }
            
        }


    };

    // const calculateTotalUnits = (updatedData) => {
    //     //console.log(updatedData)
    //     const total = updatedData.total_units_value + ' '+updatedData.units;
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       total_units: total,
    //     }));
    //   };
      
    const handleChangeInventory = (e) => {
        const { name, value, label } = e.target;
        let newNames = [];

        // If the name is for supervisors, get the corresponding names
        if (name === 'supervisor_ids') {
          newNames = value.map(id => {
            const supervisor = supervisors.find(s => s.value === id);  // Find the supervisor by ID
            return supervisor ? supervisor.name : '';  // Get the name or an empty string if not found
          });
        setInventoryForm((prevData) => ({
            ...prevData,
            [name]: value ,
            names:newNames,
            supervisors: value
          }));
        }else{
            console.log("=====================name")
             setInventoryForm((prevData) => ({
            ...prevData,
            [name]: value ,
            names:newNames,
            supervisors:prevData.supervisor_ids
          }));
        }
       

        
    };



    const getDetails = async () => {
        const users = await CustomAxios.get(`get_users/`, {
        }).then(res => {
            const options = res?.data?.users
                .filter((item) => item.role === 'supervisor')
                .map((item) => ({
                    name: `${item.first_name} ${item.last_name}`,
                    value: item.id
                }));
            dispatch(updateInventrySupervisors(options))
        })

    }

    useEffect(() => {
        getInventerory()
        getDetails()
        GetProducts()
        getInventeroryDetails()
    }, [])

    //console.log(">>>>>>>>>>>>>>>>>>>", inventory)
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <Box component="img" alt={option.name} src={option.image} sx={{ width: '16px', height: "16px", objectFit: "cover" }} />
                    <Box>{option.name}</Box>
                </Box>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <Box sx={{ display: "flex", gap: "10px" }}>
                <Box component="img" alt={option.name} src={option.image} sx={{ width: '30px', height: "30px", objectFit: "cover" }} />
                <Box>{option.name}</Box>
            </Box>
        );
    };

    const handleSaveInventory = async () => {
        try{
            setLoading(true);
            await validationSchema.validate(inventoryform, { abortEarly: false });
        setErrors({});
        const response = await CustomAxios.post(`inventory/create/`, inventoryform, {
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory Created Successfully', life: 3000 });
            getInventerory()
            dispatch(updateInventoryModal(false))
            setLoading(false);
        }).catch(err => { console.log(err)
        setLoading(false) });
        }
        catch (validationErrors) {
            if (validationErrors.name === "ValidationError") {
              const formErrors = {};
              validationErrors.inner.forEach((error) => {
                formErrors[error.path] = error.message;
              });
              setErrors(formErrors);
              setLoading(false);
            } 
          }
        
    }


    const handleSaveInventoryDetails = async () => {
        try{
        setLoading(true)
            await validationSchemaInventoryDetails.validate(formData, { abortEarly: false });
        setErrors({});
        const response = await CustomAxios.post(`inventory/details/create/`, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory details Created Successfully', life: 3000 });
            getInventeroryDetails()
            setLoading(false)
        }).catch(err => { 
            if(err.response.data[0]=='This product is already associated with the given inventory.'){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'This product is already associated with the given inventory.', life: 3000 });
            }
            else if(err.response.data.non_field_errors[0]=='Total units cannot be less than available units.'){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Total units cannot be less than available units.', life: 3000 });
            }
            setLoading(false)
         });
    }
        catch (validationErrors) {
            if (validationErrors.name === "ValidationError") {
              const formErrors = {};
              validationErrors.inner.forEach((error) => {
                formErrors[error.path] = error.message;
              });
              setErrors(formErrors);
            setLoading(false)
            } 
          }
    }

    const handleEditInventoryDetails = async (id) => {
        try{
        setLoading(true)
            await validationSchemaInventoryDetails.validate(formData, { abortEarly: false });
        setErrors({});
        const response = await CustomAxios.put(`inventory/details/update/${id}/`, formData, {
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory details Updated Successfully', life: 3000 });
            getInventeroryDetails()
            setLoading(false)
        }).catch(err => { 
            if(err.response.data[0]=='This product is already associated with the given inventory.'){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'This product is already associated with the given inventory.', life: 3000 });
            }
            else if(err.response.data.non_field_errors[0]=='Total units cannot be less than available units.'){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Total units cannot be less than available units.', life: 3000 });
            }
            setLoading(false)
        });  }
        catch (validationErrors) {
            if (validationErrors.name === "ValidationError") {
              const formErrors = {};
              validationErrors.inner.forEach((error) => {
                formErrors[error.path] = error.message;
              });
              setErrors(formErrors);
            setLoading(false)
            } 
          }
    }

    const handleEditInventory = async (id) => {
    try{
        setLoading(true)
            await validationSchema.validate(inventoryform, { abortEarly: false });
        setErrors({});
        const response = await CustomAxios.put(`inventory/update/${id}/`, inventoryform, {
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Inventory Updated Successfully', life: 3000 });
            getInventeroryDetails()
            getInventerory()
            setLoading(false)
        }).catch(err => { console.log(err) 
            setLoading(false)
        });
    }
    catch (validationErrors) {
        if (validationErrors.name === "ValidationError") {
          const formErrors = {};
          validationErrors.inner.forEach((error) => {
            formErrors[error.path] = error.message;
          });
          setErrors(formErrors);
          setLoading(false)
        } 
      }
    }

  

      const capacity = [
        { name: 'grams', value: 'grams' },
        { name: 'kilogram', value: 'kilogram' },
        { name: 'litre', value: 'litre' },
        { name: 'box', value: 'box' },
        { name: 'cups', value: 'cups' },
    ];

    const validationSchema = Yup.object().shape({
       place: Yup.string()
       .required("place is required"),
       supervisor_ids: Yup.array()
       .of(Yup.string().required('Each item must be a string'))
       .min(1, 'At least one supervisor is required')
       .required("At least one supervisor is required"),
   });

   const validationSchemaInventoryDetails = Yup.object().shape({
    total_units: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Price must contain only digits")
    .required("Total units is required"),
    product_id: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Price must contain only digits")
    .required("product is required"),
    details_id: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Price must contain only digits")
    .required("hub name is required"),
    available_units: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, "Price must contain only digits")
    .required("Available units is required"),
    units: Yup.string()
    .required("units is required"),
});
    console.log(formData)
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%",padding:"30px" }}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "24px" }}>Product Management</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}>Inventory</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ position: "relative" }}>
                    <InputBase sx={{ backgroundColor: "white", height: "32px", width: "100%", maxWidth: "268px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px 5px 40px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Search item name" />
                    <SearchIcon sx={{ position: "absolute", left: "12px", top: "5px", width: "20px", height: "20px" }} />
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }}>
                    <Button variant="contained" onClick={handleOpenInventoryModal} sx={{ textTransform: "none" }}> Add Inventory</Button>
                    <Button variant="contained" onClick={handleOpen} sx={{ textTransform: "none" }}> Add Inventory Details</Button>
                </Box>
            </Box>
            <InventoryTable />
            <InventoryDetailsTable />
            <Modal
                open={inventoryDetailsModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: "973px", height: "687px", backgroundColor: 'white', }}>
                    <Box sx={{ height: "46px important", padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "14", fontWeight: "500", lineHeight: "22px" }}> {inventoryDetailsEdit ? 'Edit Inventory Detils' : 'Add Inventory Detils'}</Typography>
                    </Box>
                    <Box >
                        <Box sx={{ padding: "24px", display: "flex", gap: "16px", flexDirection: "column", height: "590px", overflowY: "auto" }}>
                            <Box sx={{ display: "flex", gap: "16px" }}>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }} >Hub</Typography>
                                    <Dropdown onChange={handleChange} value={formData.details_id} options={inventory} name="details_id" optionLabel="name" style={{ zIndex: "1000" }}
                                        placeholder="Select Hub" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                            {errors.details_id && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.details_id}</Typography>}
                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }} >Products</Typography>
                                    <Dropdown onChange={handleChange} value={formData.product_id} options={products} name="product_id" optionLabel="name" style={{ zIndex: "1000" }}
                                        placeholder="Select Product" className="w-full md:w-14rem"
                                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                    />
                            {errors.product_id && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.product_id}</Typography>}
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: "16px" }}>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Total Units</Typography>
                                    <Box sx={{display:"flex",gap:"5px"}}>
                                <Box sx={{display:"flex",flexDirection:"column"}}>
                                    <InputBase value={formData.total_units} sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter total" name="total_units" onChange={handleChange} />
                                {errors.total_units && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.total_units},</Typography>}
                                </Box>
                                    <Box sx={{display:"flex",flexDirection:"column"}}>
                                    <Dropdown value={formData.units} style={{width:"100px", height: "32px"}}  onChange={handleChange} options={capacity} optionLabel="name" name="units"
                                        placeholder="Select units" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                            {errors.units && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.units}</Typography>}
                                    </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Availabile Units</Typography>
                                    <InputBase value={formData.available_units} sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter available units" name="available_units" onChange={handleChange} />
                            {errors.available_units && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.available_units}</Typography>}
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: "16px" }}>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "20PX", fontWeight: "400", lineHeight: "22px" }}>Supervisors:</Typography>
                                    {Hubsupervisors?.map((supervisor, ind) => (
                                        <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px", marginLeft: "20px" }}>{ind + 1}. {supervisor}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", position: "absolute", bottom: 0, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
                            <Button variant="outlined" sx={{ textTransform: "none", color: "black", backgroundColor: "white", height: "32px", width: "67px" }} onClick={handleClose}> cancel</Button>
                            <Button variant="contained" sx={{ textTransform: "none", height: "32px", width: "67px" }} onClick={() => inventoryDetailsEdit ? handleEditInventoryDetails(inventoryDetails.inventory_details_id) : handleSaveInventoryDetails()} > {inventoryDetailsEdit ? loading?<CircularProgress size="25px" sx={{color:"white"}}/>:'Update' : loading?<CircularProgress size="25px" sx={{color:"white"}}/>:'Save'}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={inventoryModal}
                onClose={handleCloseInventoryModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: "600px", height: "500px", backgroundColor: 'white', }}>

                    <Box sx={{ height: "46px important", padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "14", fontWeight: "500", lineHeight: "22px" }}>{InventoryEdit ? 'Edit inventory' : ' Add Inventory'}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", padding: "30px" }}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Hub Name</Typography>
                            <InputBase value={inventoryform.place} sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter hub name" name="place" onChange={handleChangeInventory} />
                            {errors.place && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.place}</Typography>}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }} >Supervisors</Typography>
                            <MultiSelect onChange={handleChangeInventory} value={inventoryform.supervisor_ids} options={supervisors} name="supervisor_ids" optionLabel="name" style={{ zIndex: "1000" }}
                                placeholder="Select supervisor" className="w-full md:w-20rem" />
                                {errors.supervisor_ids && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.supervisor_ids}</Typography>}
                        </Box>
                    </Box>
                    <Box >
                    </Box>
                    <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", position: "absolute", bottom: 0, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
                            <Button variant="outlined" sx={{ textTransform: "none", color: "black", backgroundColor: "white", height: "32px", width: "67px" }} onClick={handleCloseInventoryModal}> cancel</Button>
                            <Button variant="contained" sx={{ textTransform: "none", height: "32px", width: "67px" }} onClick={() => InventoryEdit ? handleEditInventory(inventoryform.value) : handleSaveInventory()} > {InventoryEdit ? loading?<CircularProgress size="25px" sx={{color:"white"}}/>:'update' : loading?<CircularProgress size="25px" sx={{color:"white"}}/>:'Save'}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}