import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    productdetail: {
        item_name: "",
        item_description: "",
        item_category: "",
        item_criteria: "",
        images: [],
        item_details: "",
        // product_group_id: "",
        capacitydetails: [{ 
            item_price: "",
            item_available: true,
            item_capacity: "",
            item_quantity: "",
            item_tax: "",
            item_selling_price: "",
            item_offers_price: 0.00,
            capacityValue: "",
            unit: "",
            extraPercentage : ""
        }]
    },
    edit:false,
    products: [],
    modal: false,
    loading:false,
};


const productsReducer = createSlice({
    name: "productsReducer",
    initialState: initialState,
    reducers: {
        updateProductDetail: (state, { type, payload }) => {
            state.productdetail = payload
        },
        updateProducts: (state, { type, payload }) => {
            state.products = payload
        },
        updateModal: (state, { type, payload }) => {
            state.modal = payload
        },
        updateEdit: (state, { payload }) => {
            state.edit = payload
        },
        updateLoadingProducts: (state, { type, payload }) => {
            state.loading = payload
        },
    }
})

export const { updateProducts, updateModal, updateProductDetail ,updateEdit ,updateLoadingProducts} = productsReducer.actions



export default productsReducer.reducer;