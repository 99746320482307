import { Box, Button, InputBase, Modal, Typography, Input, IconButton, Tooltip,Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updatesearchProduct } from "../../Redux/page";
import SearchIcon from '@mui/icons-material/Search';
import { ProductTable } from "../../sub_components/product_table";
import { useEffect, useState } from "react";
import { Editor } from "primereact/editor";
import AddIcon from '@mui/icons-material/Add';
import '../../App.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dropdown } from 'primereact/dropdown';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateEdit, updateModal, updateProductDetail, updateProducts } from "../../Redux/products";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import * as Yup from "yup";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CustomAxios from "../../utils/CustomAxios";
import { OrderModal, OrderModalDelivery, useGetProducts } from "../globalFunctions";
import { updateUpdatedDate } from "../../Redux/orders";
import CircularProgress from '@mui/material/CircularProgress';


export function ProductDetails() {
    const dispatch = useDispatch()
    const toast = useRef(null);
    const authToken = localStorage.getItem('authtoken')
    const modal = useSelector(state => state.products.modal)
    const productDetails = useSelector(state => state.products.productdetail)
    const edit = useSelector(state => state.products.edit)
    const [imagePreviews, setImagePreviews] = useState([]);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState([]);
    const getProducts = useGetProducts()


    const handleExtraPercentageChange = (index, value) => {
        const updatedCapacityDetails = formData.capacitydetails.map((item, i) => {
            if (i === index) {
                const extraPercentage = value === '' ? 0 : parseFloat(value) || 0;
                const price = parseFloat(item.item_price) || 0;
                const priceWithExtra = price + (price * extraPercentage) / 100;
    
                return {
                    ...item,
                    extraPercentage: value === '' ? '' : extraPercentage,
                    item_selling_price: priceWithExtra.toFixed(2), 
                };
            }
            return item;
        });
    
        setFormData({ ...formData, capacitydetails: updatedCapacityDetails });
    };
    
 

    useEffect(() => {
        const updatedCapacityDetails = formData?.capacitydetails?.map((item) => {
            const price = parseFloat(item.item_price) || 0;
            const extraPercentage = parseFloat(item.extraPercentage) || 0;

            const priceWithExtra = price + (price * extraPercentage) / 100;
    
            return {
                ...item,
                item_selling_price: priceWithExtra.toFixed(2),
            };
        });
    
        setFormData({ ...formData, capacitydetails: updatedCapacityDetails });
    }, []);
    
    
    useEffect(() => {
        console.log(productDetails)
        setFormData(productDetails)
        dispatch(updateUpdatedDate(null))
    }, [productDetails])
    useEffect(() => {
        if (edit) {

            console.log("-------------->")
            setImagePreviews(productDetails?.image_urls)
            const convert = async () => {
                const fetchedImages = await Promise.all(
                    productDetails?.image_urls?.map(async (url) => {
                        //console.log(url)
                        const response = await fetch(url);
                        //console.log(response)
                        const blob = await response.blob();
                        const file = url.split('/').pop();
                        //console.log(file)
                        return new File([blob], file, { type: "image/jpeg" });
                    })
                );
                //console.log(fetchedImages)
                setFormData(prevData => ({
                    ...prevData,
                    images: fetchedImages
                }));
            };

            convert();
            setFormData(prevData => ({
                ...prevData,
                ...productDetails,
            }));
        }
    }, [edit])
    //console.log(productDetails)
    const extractPlainText = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    const handleChange = (e) => {
        //console.log(e)
        if (e.htmlValue !== undefined) {
            const name = e.target ? e.target.name : "item_details";
            const value = extractPlainText(e.htmlValue);

            setFormData({
                ...formData,
                [name]: value
            });

            // dispatch(updateProductDetail(name, value));

        } else {
            const name = e.target.name;
            let value
            if (name==="shinos_choice"){
               value = e.target.checked 
            }
            else{
                value=e.target.value
            }

            //console.log(value, name)
            setFormData({
                ...formData,
                [name]: value
            });
            // dispatch(updateProductDetail(name, value));
        }

    };
    // const handleCapacityChange = (index, e) => {
    //     const updatedCapacityDetails = formData?.capacitydetails?.map((item, i) =>
    //       i === index ? { ...item, [e.target.name]: e.target.value } : item
    //     );
    //     //console.log(updatedCapacityDetails)
    //     setFormData({ ...formData, capacitydetails: updatedCapacityDetails });
    //   }

    console.log(formData)


    
    const handleCapacityChange = (index, e) => {
        const { name, value } = e.target;
      
        const updatedCapacityDetails = formData?.capacitydetails?.map((item, i) => {
          if (i === index) {
            const updatedItem = { ...item, [name]: value };
            
            const price = parseFloat(updatedItem.item_price) || 0;
            const extraPercentage = parseFloat(updatedItem.extraPercentage) || 0;
      
            if (name === 'item_price' || name === 'extraPercentage') {
              const priceWithExtra = price + (price * extraPercentage) / 100;
              updatedItem.item_selling_price = priceWithExtra.toFixed(2);
            }

            if (name === 'capacityValue' || name === 'unit') {
              const capacityValue = updatedItem.capacityValue || 'N/A';
              const unit = updatedItem.unit || 'units';
      
              if (capacityValue !== '' && unit !== '') {
                updatedItem.item_capacity = `${capacityValue} ${unit}`;
              }
            }
      
            return updatedItem;
          }
          return item;
        });
      
        setFormData({ ...formData, capacitydetails: updatedCapacityDetails });
      };
      
      
    

      const handleAddCapacity = () => {
        //console.log(formData)
        setFormData({
          ...formData,
          capacitydetails: [...formData.capacitydetails, { 
            item_price: "",
            item_available: true,
            item_capacity: "",
            item_quantity: "",
            item_tax: "",
            item_selling_price: "",
            item_offers_price:  0.00,

           }],
        });
      };

      const handleRemoveCapacity = (index) => {
        const updatedCapacityDetails = formData.capacitydetails.filter((_, i) => i !== index);
        setFormData({ ...formData, capacitydetails: updatedCapacityDetails });
      };

    const category = [
        { name: 'Food', value: 'food' },
        { name: 'Meat', value: 'meat' },
        { name: 'Vegetables', value: 'vegetables' },
        { name: 'Provisions', value: 'food' },
        { name: 'Oil & Sauces', value: 'Oil & Sauces' },
        { name: 'Dairy & Breads', value: 'Dairy & Breads' },
        { name: 'Mojitos', value: 'Mojitos' },
        { name: 'Cool Drinks', value: 'Cool Drinks' },
    ];
    const criteria = [
        { name: 'Veg', value: 'veg' },
        { name: 'Non - veg', value: 'non-veg' },
    ];
    const capacity = [
        { name: 'grams', value: 'gms' },
        { name: 'kilogram', value: 'kg' },
        { name: 'litre', value: 'ltr' },
        { name: 'box', value: 'box' },
        { name: 'cups', value: 'cups' },
    ];
    const availability = [
        { name: 'Available', value: true },
        { name: 'Out of stock', value: false },
    ];

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData({
            ...formData,
            images: [...formData?.images, ...files]
        });
        if (edit) {
            const url = URL.createObjectURL(e.target.files[0]);
            setImagePreviews(prev => ([...prev, url]));
        }else{
            const previews = files?.map((file) => URL.createObjectURL(file));
            setImagePreviews(prev => ([...prev, ...previews]));
        }
        // dispatch(updateProductDetail('images', files));
    };

    // useEffect(() => {
    //     if (formData?.images?.length > 0 && !edit && !modal) {
    //         console.log("in photo");
    //         const previews = formData.images.map((file) => URL.createObjectURL(file));
    //         setImagePreviews(previews);
    //     }
    //     return () => {
    //         formData?.images?.forEach((file) => URL.revokeObjectURL(file));
    //     };
    // }, [formData?.images, edit,modal]);
    const handleRemoveImage = (index) => {
        const newImages = formData?.images?.filter((_, i) => i !== index);
        //console.log(newImages)
        setFormData((prevData) => ({ ...prevData, images: newImages??[] }));
        if (!edit) {
            setImagePreviews(formData?.images?.filter((_, i) => i !== index));
        } else {
            const newImagesurls = formData?.image_urls?.filter((_, i) => i !== index);
            setFormData((prevData) => ({ ...prevData, images: newImages??[],image_urls:newImagesurls }));
            setImagePreviews(imagePreviews?.filter((_, i) => i !== index));
        }

        // dispatch(updateProductDetail('images', formData.images.filter((_, i) => i !== index)));
    };

    const handleOpen = () => {
        setErrors({})
        dispatch(updateProductDetail({
            item_name: "",
            item_description: "",
            item_category: "",
            item_criteria: "",
            images: [],
            item_details: "",
            // product_group_id: "",
            capacitydetails: [{ 
                item_price: "",
                item_available: true,
                item_capacity: "",
                item_quantity: "",
                item_tax: "",
                item_selling_price: "",
                item_offers_price:  0.00,
            extraPercentage : ""

            }]
        }));
        dispatch(updateEdit(false));
        dispatch(updateModal(true))
    };
    const handleClose = () => {
        setErrors({})
        dispatch(updateModal(false)); 
        dispatch(updateEdit(false))
        dispatch(updateProductDetail({
            item_name: "",
            item_description: "",
            item_category: "",
            item_criteria: "",
            images: [],
            item_details: "",
            // product_group_id: "",
            capacitydetails: [{ 
                item_price: "",
                item_available: true,
                item_capacity: "",
                item_quantity: "",
                item_tax: "",
                item_selling_price: "",
                item_offers_price: 0.00,
                extraPercentage : ""

            }]
        }));
        setImagePreviews([])
    };


    const handleSaveProducts = async () => {
        const data = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            if (Array.isArray(value)) {
                //console.log(value)
                if(key =='images'){
                   value.forEach((file) => {
                    if (file instanceof File) {
                        console.log(key, file)
                        data.append(key, file);
                    } else {
                        console.error(`Expected a File object, but received:`, file);
                    }
                    
                }); 
            }else if (key =='capacitydetails'){
                data.append('capacitydetails', JSON.stringify(formData.capacitydetails));
                }
                
            } else {
                //console.log(key, value)

                data.append(key, value);
            }
        }
        try{

            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            for (const [key, value] of data.entries()) {
                console.log(`${key}:`, value);
            }
            if(data){
                setLoading(true);
                const response = await CustomAxios.post(`products/add/`, data, {
                }).then(res => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Product Added Successfully', life: 3000 });
                    getProducts()
                    handleClose()
                    setFormData([])
                    dispatch(updateModal(false))
                    setLoading(false);
                })
            }
        }
        catch (validationErrors) {
            if (validationErrors.name === "ValidationError") {
              const formErrors = {};
              validationErrors.inner.forEach((error) => {
                formErrors[error.path] = error.message;
              });
              setErrors(formErrors);
              setLoading(false);
            } 
          }
    }
    //console.log(edit)
    const handleUpdateProducts = async (id) => {
        const data = new FormData();
        //console.log("inside updateModal",formData)
        for (let [key, value] of Object.entries(formData)) {
            if (Array.isArray(value)) {
                if(key =='images'){
                    value.forEach((file) => {
                     if (file instanceof File) {
                         console.log(key, file)
                         data.append(key, file);
                     } else {
                         console.error(`Expected a File object, but received:`, file);
                     }
                     
                 }); 
             }else if(key =='capacitydetails'){
                //console.log(formData)
                const updatedCapacityDetails = value.map((capacityDetail) => {
                    return {
                        ...capacityDetail,
                        capacity: `${capacityDetail.capacityValue} ${capacityDetail.unit}`,
                    };
                });
                 data.append('capacitydetails', JSON.stringify(updatedCapacityDetails));
                 }
            } else {
                data.append(key, value);
            }
        }
        try{
            setLoading(true);
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
        const response = await CustomAxios.put(`products/update/${id}/`, data, {
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Product updates Successfully', life: 3000 });
            getProducts()
            handleClose()
            setFormData([])
            dispatch(updateModal(false))
            setLoading(false)
        })}
        catch (validationErrors) {
            if (validationErrors.name === "ValidationError") {
              const formErrors = {};
              validationErrors.inner.forEach((error) => {
                formErrors[error.path] = error.message;
              });
              setErrors(formErrors);
              setLoading(false);
            } else if (axios.isAxiosError(validationErrors)) {
              
            }
          }
    }

    //console.log(formData)
    //console.log("images-------------",imagePreviews)

    const validationSchema = Yup.object().shape({
           item_name: Yup.string()
          .required("Name is required"),
          item_description: Yup.string()
          .required("Description is required"),
          item_details: Yup.string()
          .required("Details is required"),
          item_category: Yup.string()
          .required("category is required"),
          item_criteria: Yup.string()
          .required("criteria is required"),
          //   item_quantity: Yup.string()
          //   .matches(/^\d+(\.\d{1,2})?$/, "Quantity must contain only digits")
          //   .required("Quantity is required"),
          images: Yup.array()
          .of(Yup.string().required('Each item must be a string'))
          .min(1, 'At least one image is required')
          .required("At least one image is required"),
          capacitydetails: Yup.array().of(
              Yup.object().shape({
                //   item_capacity: Yup.string()
                //   .required("capacity is required"),
                  item_price: Yup.string()
                  .matches(/^\d+(\.\d{1,2})?$/, "Price must contain only digits")
                  .required("Price is required"),
                  item_tax: Yup.string()
                  .matches(/^\d+(\.\d{1,2})?$/, "Tax must contain only digits")
                  .required("Tax is required"),
                  item_offers_price: Yup.string()
                  .matches(/^\d+(\.\d{1,2})?$/, "Offers price must contain only digits")
                  .required("Offers is required"),
                  item_selling_price: Yup.string()
                  .matches(/^\d+(\.\d{1,2})?$/, "Selling price must contain only digits")
                  .required("Selling is required"),
                  capacityValue: Yup.string()
                  .required("capacityValue is required"),
                  unit: Yup.string()
                  .required("unit is required"),

            })
        ),
      });
      console.log(errors)
    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" ,padding:"30px"}}>
            <Toast ref={toast} />
            <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition="Bounce" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px",marginBottom:"20px" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "24px" }}>Product Management</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}>Product Details</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",marginBottom:"30px" }}>
                <Box sx={{ position: "relative" }}>
                    <InputBase sx={{ backgroundColor: "white", height: "32px", width: "100%", maxWidth: "268px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px 5px 40px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} onChange={(e) => dispatch(updatesearchProduct(e.target.value))} placeholder="Search item name" />
                    <SearchIcon sx={{ position: "absolute", left: "12px", top: "5px", width: "20px", height: "20px" }} />
                </Box>
                <Button variant="contained" onClick={handleOpen} sx={{ textTransform: "none" }}> Add Product</Button>
            </Box>
            <ProductTable />
            <Modal
                open={modal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 1000 }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: "1200px", height: "687px", backgroundColor: 'white', }}>
                    <Box sx={{ height: "46px important", padding: "12px 24px", backgroundColor: "#EEEEEE", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "14", fontWeight: "500", lineHeight: "22px" }}> {edit ? "Edit Product" : "Add Product"}</Typography>
                    </Box>
                    <Box >
                        <Box sx={{ padding: "24px", display: "flex", gap: "16px", flexDirection: "column", height: "590px", overflowY: "auto" }}>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Name</Typography>
                                <InputBase value={formData.item_name} sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter product name" name="item_name" onChange={handleChange} />
                                {errors.item_name && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.item_name}</Typography>}
                            </Box>
                            <Box sx={{ width: "100%", display: "flex", gap: "16px" }}>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }} name="item_category">category</Typography>
                                    <Dropdown value={formData.item_category} onChange={handleChange} options={category} name="item_category" optionLabel="name" style={{ zIndex: "1000" }}
                                        placeholder="Select category" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                                {errors.item_category && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.item_category}</Typography>}

                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }} >criteria</Typography>
                                    <Dropdown value={formData.item_criteria} onChange={handleChange} options={criteria} optionLabel="name" name="item_criteria"
                                        placeholder="Select criteria" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                                {errors.item_criteria && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.item_criteria}</Typography>}

                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "row", gap: "10px", alignItems:"center", justifyContent:"center" }}>
                                    <Checkbox onChange={handleChange} checked={formData.shinos_choice===true?true:false} name="shinos_choice"/>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }} >Shino's Choice {formData.shinos_choice?"Checked":"Unchecked"}</Typography>
                                    {/* <Dropdown value={formData.item_criteria} onChange={handleChange} options={criteria} optionLabel="name" name="item_criteria"
                                        placeholder="Select criteria" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} /> */}
                                    

                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Description</Typography>
                                <InputBase value={formData.item_description} sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter product description" name="item_description" onChange={handleChange} />
                                {errors.item_description && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.item_description}</Typography>}
                            </Box>
                            <Editor style={{ height: '135px' }} name="item_details" value={formData.item_details} onTextChange={handleChange} />
                                {errors.item_details && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.item_details}</Typography>}
                            {formData?.capacitydetails?.map((capacityDetail, index) => (
                            <Box sx={{ width: "100%", display: "flex", gap: "16px",alignItems:"flex-end" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Capacity</Typography>
                                    {/* <Dropdown value={capacityDetail.item_capacity}  onChange={(e) => handleCapacityChange(index, e)} options={capacity} optionLabel="name" name="item_capacity"
                                        placeholder="Select capacity" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} /> */}
                                    <Box sx={{display:"flex",gap:"5px"}}>
                                    <InputBase value={capacityDetail.capacityValue} name="capacityValue" sx={{ backgroundColor: "white", width: "100px", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: errors[`capacitydetails[${index}].capacityValue`] ? 'red' :"secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Add Capacity"  onChange={(e) => handleCapacityChange(index, e)} />
                                        <Dropdown value={capacityDetail.unit} style={{width:"100px", height: "32px", borderColor: errors[`capacitydetails[${index}].unit`] ? 'red' :"#D9D9D9"}}  onChange={(e) => handleCapacityChange(index, e)} options={capacity} optionLabel="name" name="unit"
                                        placeholder="Select capacity" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                                </Box>
                                
                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Price</Typography>
                                    <InputBase value={capacityDetail.item_price} name="item_price" sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor:  errors[`capacitydetails[${index}].item_price`] ? 'red' :"secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter Product price"  onChange={(e) => handleCapacityChange(index, e)} />
                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Special Offer</Typography>
                                    <InputBase value={capacityDetail.item_special_offer} name="item_special_offer" sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: errors[`capacitydetails[${index}].item_special_offer`] ? 'red' :"secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter Product Special Offer"  onChange={(e) => handleCapacityChange(index, e)} />
                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Offer price</Typography>
                                    <InputBase value={capacityDetail.item_offers_price} name="item_offers_price" sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: errors[`capacitydetails[${index}].item_offers_price`] ? 'red' :"secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter Product offer price"  onChange={(e) => handleCapacityChange(index, e)} />
                                </Box>
                               
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Margin</Typography>
                                    <InputBase 
                                        value={capacityDetail.extraPercentage ?? '0'} 
                                        name={`extra_percentage_${index}`} 
                                        sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} 
                                        placeholder="Enter Selling Percentage" 
                                        onChange={(e) => handleExtraPercentageChange(index, e.target.value)}
                                        onBlur={() => {
                                        if (capacityDetail.extraPercentage === '') {
                                            handleExtraPercentageChange(index, '0');
                                        }
                                        }}
                                    />
                                </Box>
                                
                                
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "6pxpx" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>selling Price</Typography>
                                    <InputBase value={capacityDetail.item_selling_price} name="item_selling_price" sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: errors[`capacitydetails[${index}].item_selling_price`] ? 'red' :"secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter Product selling price" onChange={(e) => handleCapacityChange(index, e)} />
                                </Box>
                                {/* <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Quantity</Typography>
                                    <InputBase value={formData.item_quantity} name="item_quantity" sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} onChange={handleChange} />
                                {errors.item_quantity && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.item_quantity}</Typography>}
                                </Box> */}
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Tax %</Typography>
                                    <InputBase value={capacityDetail.item_tax} name="item_tax" sx={{ backgroundColor: "white", width: "100%", height: "32px", borderRadius: "2px", border: "1px solid", borderColor: errors[`capacitydetails[${index}].item_tax`] ? 'red' :"secondary.border", padding: "5px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} placeholder="Enter Product Tax %" onChange={(e) => handleCapacityChange(index, e)} />
                                </Box>
                                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <Typography sx={{ fontSize: "14PX", fontWeight: "400", lineHeight: "22px" }}>Availability</Typography>
                                    <Dropdown value={capacityDetail.item_available} onChange={(e) => handleCapacityChange(index, e)} options={availability} optionLabel="name" name="item_available"
                                        placeholder="Select availability" className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                                </Box>
                                <Tooltip title="Remove" arrow>
                                <IconButton type="button" onClick={() => handleRemoveCapacity(index)}>
                                    <RemoveCircleOutlineOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                             ))}

                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Tooltip title="Add More Capacity" arrow>
                                <Button variant="outlined" sx={{ border: "2px dotted grey", width: "150px" }}><Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} onClick={handleAddCapacity}>Add Capacity</Typography></Button>
                            </Tooltip>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px',alignItems:"center" }}>
                                {imagePreviews?.map((image, index) => (
                                    <Box key={index} sx={{ width: '104px', height: '104px', border: '1px solid', borderColor: 'secondary.border', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', cursor: 'pointer', backgroundColor: 'background.upload', overflow: 'hidden', '&:hover .overlay': { display: 'block', }, '&:hover .delete-button': { display: 'flex', }, }}>
                                        <img src={image} alt={`Uploaded ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', transition: 'opacity 0.3s', }} />
                                        <Box className="overlay" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080', display: 'none', borderRadius: '8px', }} />
                                        <Tooltip title="Delete" arrow>
                                            <IconButton className="delete-button" onClick={() => handleRemoveImage(index)} sx={{ position: 'absolute', top: "50%", left: "50%", display: 'none', transform: 'translate(-50%, -50%)', }}>
                                                <DeleteOutlineIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                ))}
                                <Box
                                    sx={{ width: '104px', height: '104px', border: '1px solid', borderColor: 'secondary.border', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', cursor: 'pointer', backgroundColor: 'background.upload', }}>
                                    <input type="file" accept="image/*" id="upload-input" style={{ position: 'absolute', width: '104px', height: '104px', opacity: 0, cursor: 'pointer', top: 0, left: 0 }} onChange={handleImageChange} name="images" />
                                    <label htmlFor="upload-input" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <AddIcon fontSize="large" />
                                        <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px" }}>Upload</Typography>
                                    </label>
                                </Box>
                            {errors.images && <Typography style={{ color: "red" ,  fontSize: "12PX", fontWeight: "400", lineHeight: "22px"  }}>{errors.images}</Typography>}
                            </Box>
                        </Box>

                    </Box>
                    <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", position: "absolute", bottom: 0, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <Box sx={{ padding: "12px 24px", display: "flex", gap: "20px" }}>
                            <Button variant="outlined" sx={{ textTransform: "none", color: "black", backgroundColor: "white", height: "32px", width: "67px" }} onClick={handleClose}> cancel</Button>
                            <Button variant="contained" sx={{ textTransform: "none", height: "32px", width: "67px" }} onClick={() => edit ? handleUpdateProducts(productDetails.id) : handleSaveProducts()} > {edit ? loading?<CircularProgress size="25px" sx={{color:"white"}}/>:"Update" : loading?<CircularProgress size="25px" sx={{color:"white"}}/>:"Save"}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <OrderModal/>
            <OrderModalDelivery/>
        </Box>
    )
}