import { Box } from "@mui/material";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import veg from "../Assets/product_management/Foodmark.svg"
import nonVeg from "../Assets/product_management/non-veg.svg"
import { useState } from "react";
import { useEffect } from "react";

export function OrderProductsTable() {
    const toast = useRef(null);
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const [ordersDetails,setOrdersDetails] = useState({})
    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])
    console.log(orderedProducts)
    let serialNo = 1
    const calculateItemPrice = (price, tax) => {
        const txRemovePrice = (parseFloat(price) * parseFloat(tax)) / 100;
        const itemPrice = parseFloat(price) - parseFloat(txRemovePrice);
        return itemPrice.toFixed(2); // Returns a string rounded to 2 decimal places
    };

    return (<Box>
        <Toast ref={toast} />
        <TableContainer sx={{ height: "300px" }}>
            <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                <TableHead style={{ backgroundColor: "background.upload" }}>
                    <TableRow >
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">S No</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Product Name</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Criteria</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Capacity</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Quantity</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Price in Rs</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Special Offer</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">GST %</TableCell>
                        <TableCell sx={{ fontWeight: "600", textAlign: "left", backgroundColor: "background.upload" }} align="left">Total Price in Rs</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ordersDetails?.cart_items?.map((item) => {
                        return (<> {
                            item.products.map((prd,index)=>(
                                prd.capacity?.map((capacity,ind) =>{
                                    if (capacity.item_quantity>0){
                                    return(
                                         capacity.item_quantity!==""&&<TableRow key={item.id} >
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{serialNo++}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}> {<Box sx={{display:"flex",alignItems:"center"}}><Box component='img' src={item.product_details[index]?.images[0]} sx={{ width: "40px", height: "40px", borderRadius: "8px", marginRight: "10px" }}/>{item.product_details[index].item_name}</Box>}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{item.product_details[0]?.item_criteria=='veg'?<Box><Box component='img' src={veg}/> Veg</Box>:<Box><Box component='img' src={nonVeg}/> Non - Veg</Box>}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{capacity?.item_capacity}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{capacity?.item_quantity}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>Rs {capacity?.item_offers_price!=0?(parseFloat(capacity.item_quantity)*(parseFloat(calculateItemPrice(capacity.item_offers_price, capacity.item_tax)))).toFixed(2):(parseInt(capacity.item_quantity)*(calculateItemPrice(capacity.item_selling_price, capacity.item_tax))).toFixed(2)}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{capacity?.item_special_offer}</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>{capacity?.item_tax} %</TableCell>
                                <TableCell sx={{ textAlign: "left", textTransform: "capitalize", backgroundColor: "white" }}>Rs {capacity?.item_offers_price!=0?(parseFloat(capacity?.item_quantity)*parseFloat(capacity?.item_offers_price)).toFixed(2):(parseFloat(capacity?.item_quantity)*parseFloat(capacity?.item_selling_price)).toFixed(2)}</TableCell>
                            </TableRow>
                                    )}
                               
                    })
                            ))
                            }</>)
                    })

                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Box>)
}