import { Box } from "@mui/material";
import { Layout } from "../Components/layout";
import { ProductDetails } from "../Components/product_management/product_details";
import { DeliveryManagement } from "../Components/delivery_management/delivery_management";
import { DeliveryEarnings } from "../Components/delivery_earnings";
import { UserManagement } from "../Components/user_management";
import { Sidebar } from "../Components/dp-sidebar";
import { Header } from "../Components/dp-header";
import { Dashboard } from "../Components/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Inventory } from "../Components/product_management/inventory";
import { Campaign } from "../Components/product_management/campaign";
import { OrederManagement } from "../Components/order_management/order_lists_dp";
import axios from 'axios';
import { useEffect } from "react";
import { updateFirst_name, updateId, updateLast_name } from "../Redux/user";
import { useFetchOrders, useGetOrders } from "../Components/globalFunctions";
import { updatesocketStatus } from "../Redux/page";
import sound  from '../Assets/notification_tone.mp3'
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useState } from "react";
import { updateDeliveryPartners, updateselectedDeliveryPartner } from "../Redux/orders";
import CustomAxios from "../utils/CustomAxios";

export function DPHome() {
    const page = useSelector(state => state.page.dp_pageName)
    console.log(page)
    const authToken = localStorage.getItem('authtoken')
    const id = localStorage.getItem('id');
    const decodedToken = jwtDecode(authToken);
    const actorders = useSelector(state => state.orders.orders);
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const partners = useSelector(state => state.orders.deliveryPartners);
    const [orders,setOrders] = useState([])
    const [deliverypartner, setDeliverypartner]= useState()
    const mobileno = decodedToken.mobileno;
    const first_name = localStorage.getItem('first_name');
    const socketUrl = process.env.REACT_APP_SOCKET_URL
    const last_name = localStorage.getItem('last_name');
    const today = dayjs()
    console.log(id)

    useEffect(()=>{
        const orders = actorders.filter(order=>{if (order.delivery_partner_id==deliverypartner?.id){
            return order
        }})
        setOrders(orders)

    },[actorders])

  const GetFetchedOrders = useFetchOrders()
  const GetOrders = useGetOrders()
  const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(updateFirst_name(first_name))
        dispatch(updateLast_name(last_name));
        dispatch(updateId(id));

  },[authToken])
  useEffect(()=>{
    setDeliveryPartners(partners)
},[partners])

const getUsers = async () => {
    const users = await CustomAxios.get(`get_users/`, {
    }).then(res => {
        console.log("--------->",res.data)
        let deliveryPartners = res.data.users.filter((user)=>
            user.role ==='delivery' && user.mobileno==mobileno
        )
        console.log(deliveryPartners)
        dispatch(updateDeliveryPartners(deliveryPartners))
    })

}
useEffect(() => {
    getUsers()
}, [])
  useEffect(() => {
    console.log("socketUrl",socketUrl)
      const ws = new WebSocket(`wss://${socketUrl}/ws/admin/partner/`); 
  
      ws.onopen = () => {
        console.log('WebSocket connected');
        ws.send(JSON.stringify({ source: 'request.list' }));
        dispatch(updatesocketStatus(true))
        GetOrders(today, deliverypartner)
      };
  
      ws.onmessage = (event) => {
        const parsedMessage = JSON.parse(event.data);
        console.log('Message received:', parsedMessage);
        if(parsedMessage.status=='delivery_partner_assigned'){
          alarm()
          GetOrders()
          GetFetchedOrders(today, deliverypartner)
        }
        // toast.current.show({ severity: 'info', summary: parsedMessage.order_id, detail: parsedMessage.message, life: 3000 })
        
      };
  
      ws.onerror = (error) => {
        console.error('WebSocket error:', error.message);
        dispatch(updatesocketStatus(false))
      };
  
      ws.onclose = () => {
        console.log('WebSocket connection closed');
        dispatch(updatesocketStatus(false))
      };
  
  
      return () => {
        ws.close();
        console.log('WebSocket closed on unmount');
        dispatch(updatesocketStatus(false))
      };
    }, [authToken]);
    useEffect(()=>{
        const delivery_partner = deliveryPartners.find((partner)=>partner.mobileno === mobileno)
        console.log("41 -------------------------------- deliverypartner",mobileno,deliverypartner)
        setDeliverypartner(delivery_partner)
        dispatch(updateselectedDeliveryPartner(delivery_partner))

    },[deliveryPartners])
    const alarm = () => {
        new Audio(sound).play()
      }
      console.log(deliveryPartners,mobileno)
    return (<Box sx={{ width: "100%", height: "100vh", backgroundColor: 'background.default' }}>
        <Header />
        <Box sx={{ display: "flex",height:'calc( 100vh - 72px)',overflowY:'auto' }}>
            <Sidebar/>
            <Box sx={{width:"100%" }}>
                {/* {page === "dashboard" && <Dashboard />}

                {page === "product_details" && <ProductDetails />}
                {page === "campaign" && <Campaign />}
                {page === "inventory" && <Inventory />} */}

                {page === "order_management" && <OrederManagement />}
                {page === "dp-earnings" && <DeliveryEarnings />}
                {/* {page === "user_management" && <UserManagement />} */}
            </Box>

        </Box>
    </Box>)
}