import { Box, Button, InputBase, Modal, Typography, Input, IconButton, Tooltip, Select, MenuItem, getListSubheaderUtilityClass, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchOrders, updatesearchProduct } from "../../Redux/page";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { OrderListTable } from "../../sub_components/orders_table";
import CustomAxios from "../../utils/CustomAxios";
import CustomAxiosDP from "../../utils/CustomAxiosDP";
import { updateDeliveryPartners, updateNewDeliveryPartners, updateOrderDetails, updateOrderModel, updateOrders, updatePartnerAssignModal, updateUpdatedDate, updatefilteredOrders, updateselectedDeliveryPartner } from "../../Redux/orders";
import { OrderProductsTable } from "../../sub_components/ordered_products_table";
import { format, subDays, addDays } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import '../../App.css';
import {  OrderModal, OrderModalDelivery, useDateformate, useFetchOrders, useGetOrders } from "../globalFunctions";
import { json } from "react-router-dom";

export function OrederManagement() {
    const dispatch = useDispatch()
    const toast = useRef(null);
    const orders = useSelector(state => state.orders.orders);
    const filteredOrders = useSelector(state => state.orders.filteredOrders);
    const orderModal = useSelector(state => state.orders.orderModal);
    const deliveryPartnerModal = useSelector(state => state.orders.partnerAssignModal);
    const orderedProducts = useSelector(state => state.orders.orderDetails);
    const partners = useSelector(state => state.orders.deliveryPartners);
    const [orderDetails,setOrdersDetails] = useState({})
    const [deliveryPartners,setDeliveryPartners] = useState([])
    const [delivery_data, setDeliveryData] = useState([]);
    const delivery_user = useSelector((state) => state.deliveryUser.delivery);
    const selectedDeliveryPartner = useSelector(state => state.orders.selectedDeliveryPartner);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const updatedDate = useSelector(state => state.orders.updatedDate);
    const today = dayjs()
    const deliveryMode = 1
    const GetOrders = useGetOrders()
    const fetchOrders = useFetchOrders()
    const formatDate = useDateformate()
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setOrdersDetails(orderedProducts)
    },[orderedProducts])



 const getDeliveryDetails = async () => {
        try {
            if (delivery_user && delivery_user.length > 0) {
                // Use existing data from Redux
                const Delivery_filter = delivery_user.filter(
                    (data) => data.KYCstatus === "approved" && data.Userstatus === "active"
                );
                setDeliveryData(Delivery_filter);
            } else {
                // Fetch data from API
                const response = await CustomAxiosDP.get(`user-details/`);
                const Delivery_filter = response.data.filter(
                    (data) => data.KYCstatus === "approved" && data.Userstatus === "active"
                );
                setDeliveryData(Delivery_filter);
            }
        } catch (err) {
        }
    };

const partners_data = partners.filter(partner =>
  delivery_data.some(data => {
    const mobilenoMatch = String(data.mobileno).trim() === String(partner.mobileno).trim();
    return mobilenoMatch;
  })
    ); 
    
    const totalPartner = () => {
                partners_data.length>0&&dispatch(updateNewDeliveryPartners(partners_data))}

useEffect(() => {
    // setDeliveryPartners(partners_data);
    totalPartner()
}, [partners]);
    
    
    
    useEffect(() => {
        dispatch(updateNewDeliveryPartners(partners_data))

},[])


  
    useEffect(()=>{
        updatedDate?fetchOrders(updatedDate):fetchOrders(today)
    },[orders])

    const getUsers = async () => {
        const users = await CustomAxios.get(`get_users/`, {
        }).then(res => {
            let deliveryPartners = res.data.users.filter((user)=>{
                return user.role ==='delivery'
            })
            dispatch(updateDeliveryPartners(deliveryPartners))
            dispatch(updateNewDeliveryPartners(partners_data))
        })

    }  
 
    useEffect(() => { 
        getUsers()
        getDeliveryDetails();
    },[])

    const handleCloseOrderModal = () => {
        dispatch(updateOrderModel(false))
        dispatch(updateOrderDetails({}))
        dispatch(updateselectedDeliveryPartner(""))
    };
    const handleClosedeliveryPartnerModal = () => {
        dispatch(updateselectedDeliveryPartner(""))
        dispatch(updatePartnerAssignModal(false))
    };

    const delivery_update = async(id, partner) => {
        const delivery_partner = deliveryPartners.find((part)=>part.id==partner)
        const response = await CustomAxiosDP.post('delivery_create/',{mobileno:delivery_partner.mobileno, delivery_mode:deliveryMode, delivery_status:"Pending", user_checkout_id:id}).then((response)=>{
        })
    }


    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        fetchOrders(newDate);
      };
    
      const handlePreviousDay = () => {
        const newDate = selectedDate.subtract(1, 'day');
        setSelectedDate(newDate);
        fetchOrders(newDate);
      };
    
      const handleNextDay = () => {
        const newDate = selectedDate.add(1, 'day');
        setSelectedDate(newDate);
        fetchOrders(newDate);
      };

      const viewAllOrders = () => {
        dispatch(updatefilteredOrders(orders));
      };
      const [pending,Setpending] = useState(0)
      const [assigned,SetAssigned] = useState(0)
      const [completed,SetCompleted] = useState(0)
      useEffect(()=>{
        if(filteredOrders.length>0){
            const pendingOrders = filteredOrders.filter((item) => item.status === "pending") 
            const assignedOrders = filteredOrders.filter((item) => item.status === "delivery_partner_assigned") 
            const completedOrders = filteredOrders.filter((item) => item.status === "delivered") 
            Setpending(pendingOrders.length)
            SetAssigned(assignedOrders.length)
            SetCompleted(completedOrders.length)
        }else{
            Setpending(0)
            SetAssigned(0)
            SetCompleted(0)
        }
      },[filteredOrders])
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" ,padding:"30px"}}>
            <Toast ref={toast} />
            <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition="Bounce" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", flexDirection: "column" }}>
                <Box sx={{display:"flex",justifyContent:"space-between", gap: {xs:"8px",md:"0px"}, flexDirection: {xs:"column",md:"row"}}}>
                     <Typography sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "24px" }}>Order Management</Typography>
                     <Box sx={{display:"flex",gap:"20px",alignItems:"center"}}>
                     {selectedDate.format('DD-MM-YYYY') === today.format('DD-MM-YYYY') &&<Typography sx={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px",textAlign:"center" }}> Today </Typography>}
                       <Stack direction="row" spacing={2} alignItems="center">
                            <IconButton sx={{backgroundColor:"secondary.border"}} onClick={handlePreviousDay}>
                            <ChevronLeftIcon/>
                            </IconButton>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                                className="pan-datepicker background"
                                renderInput={(params) => <TextField {...params} />}
                                sx={{
                                    "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root": {
                                        height: '32px',
                                        padding: '0 8px',
                                        border: "none",
                                        width: "200px",
                                        backgroundColor: "#FFF",
                                        zIndex: 2
                                    },
                                    "&.MuiPaper-root-MuiPickersPopper-paper": {
                                        backgroundColor: "#FFF"
                                    },
                                    "& .MuiPaper-root": {
                                        backgroundColor: "#FFF", // Background of the dropdown
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Optional: add shadow
                                        borderRadius: '8px', // Optional: rounded corners
                                    },
                                    
                                }}
                            />
                            </LocalizationProvider>
                            <IconButton sx={{backgroundColor:"secondary.border"}}  onClick={handleNextDay}>
                            <ChevronRightIcon/>
                            </IconButton>
                        </Stack>
                     </Box>
                      
                </Box>
                <Box sx={{display:"flex",gap:"20px",marginBottom:"30px"}}>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#FDE9F2",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #ED1E7933",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #F8A5C9"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{filteredOrders.length}</Typography>
                            <Typography sx={{ fontSize: {xs:"10px",sm:"14px"}, fontWeight: "400", lineHeight: "22px" }}>Total Orders</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#E6F1F8",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #0071BC33",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #80B8DE"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{pending}</Typography>
                            <Typography sx={{ fontSize: {xs:"10px",sm:"14px"}, fontWeight: "400", lineHeight: "22px", textAlign:"center" }}>New Orders</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#FFF7EB",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #FBB03B33",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #FDD089"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{assigned}</Typography>
                            <Typography sx={{ fontSize: {xs:"10px",sm:"14px"}, fontWeight: "400", lineHeight: "22px", textAlign:"center" }}>Orders in Progress</Typography>
                        </Box>
                        <Box sx={{height:"100px",maxWidth:"172px",width:"100%",backgroundColor:"#F0FDF4",borderRadius:"8px",boxShadow:"10px 10px 15px 0px #4ADE8033",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",border:"1px solid #86EFAC"}}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}>{completed}</Typography>
                            <Typography sx={{ fontSize: {xs:"10px",sm:"14px"}, fontWeight: "400", lineHeight: "22px", textAlign:"center" }}>Completed Orders</Typography>
                        </Box>
                     </Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}>Order Lists</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ position: "relative" }}>
                    <InputBase sx={{ backgroundColor: "white", height: "32px", width: "100%", maxWidth: "268px", borderRadius: "2px", border: "1px solid", borderColor: "secondary.border", padding: "5px 12px 5px 40px", fontSize: "14px", fontWeight: "400", lineHeight: "22px", color: "black" }} onChange={(e) =>
                         dispatch(updateSearchOrders(e.target.value))} placeholder="Search order Id" />
                    <SearchIcon sx={{ position: "absolute", left: "12px", top: "5px", width: "20px", height: "20px" }} />
                </Box>
                <Button variant="contained" sx={{ textTransform: "none" }} onClick={()=>viewAllOrders()}> View all</Button>
            </Box>
            <OrderListTable />
           <OrderModal/>
           <OrderModalDelivery/>
          
        </Box>
    )
}