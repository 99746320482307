import { Box, MenuItem, Select, Skeleton, Typography } from "@mui/material";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import axios from "axios";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import veg from "../Assets/product_management/Foodmark.svg";
import nonVeg from "../Assets/product_management/non-veg.svg";
import {
  updateInventoryDetail,
  updateInventoryDetailsModal,
  updateInventryHubsupervisors,
  updateallInventryDetails,
  updateinventoryDetailsEdit,
} from "../Redux/inventory";
import { useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Tooltip from "@mui/material/Tooltip";
import {
  updateOrderDetails,
  updateOrderModel,
  updateOrders,
  updatePartnerAssignModal,
  updateUpdatedDate,
  updateselectedDeliveryPartner,
} from "../Redux/orders";
import CustomAxios from "../utils/CustomAxios";
import { useFetchOrders, useGetOrders } from "../Components/globalFunctions";

export function OrderListTable() {
  const toast = useRef(null);
  const search = useSelector((state) => state.page.searchOrders);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const updatedDate = useSelector((state) => state.orders.updatedDate);
  const orderList = useSelector((state) => state.orders.filteredOrders);
  const partners = useSelector((state) => state.orders.deliveryPartners);
  const selectedDeliveryPartner = useSelector(
    (state) => state.orders.selectedDeliveryPartner
  );
  const loading = useSelector((state) => state.orders.loading);
  const GetOrders = useGetOrders();
  const fetchOrders = useFetchOrders();
  useEffect(() => {
    GetOrders();
  }, []);

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleStatusChange = async (id, newStatus, item) => {
    try {
      await CustomAxios.put(`checkout/update/${id}/`, {
        status: newStatus,
        partnerId: selectedDeliveryPartner.id,
      }).then((res) => {
        console.log(res.status);
        if (res?.status == 200) {
          if (newStatus == "delivery_partner_assigned") {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: `Delivery Partner Assigned Successfully`,
              life: 3000,
            });
            dispatch(updateselectedDeliveryPartner(""));
            dispatch(updatePartnerAssignModal(false));
            dispatch(updateOrderModel(false));
          } else {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: `Order ${res.data.order.status} Successfully`,
              life: 3000,
            });
            console.log("we are here ");
            dispatch(updateOrderModel(false));
          }

          GetOrders();
          dispatch(updateUpdatedDate(item.created_on));
        }
      });
    } catch (err) {}
  };

  const handleModalChange = (item) => {
    console.log(item);
    dispatch(updateOrderModel(true));
    dispatch(updateOrderDetails(item));
    if (item.delivery_partner_id) {
      dispatch(updateselectedDeliveryPartner(item.delivery_partner_id));
    } else {
      dispatch(updateselectedDeliveryPartner(""));
    }
  };

  const handlePartner = (partnerId) => {
    const partnerDetails = partners.filter(
      (partner) => partner.id == partnerId
    );
    console.log(loading);
    if (partnerDetails.length > 0) {
      return (
        <Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px" }}
          >
            {partnerDetails[0].first_name + " " + partnerDetails[0].last_name}{" "}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "22px" }}
          >
            Est.delivery time : 30 mins{" "}
          </Typography>
        </Box>
      );
    } else {
      return <Box>-- --</Box>;
    }
  };
  return (
    <Box>
      <Toast ref={toast} />
      <TableContainer sx={{ height: "550px" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: "background.upload" }}>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                S No
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                Order Id
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                CustomerDetails
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                Total Amount
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "600",
                  textAlign: "left",
                  backgroundColor: "background.upload",
                }}
                align="left"
              >
                Delivery Partner
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList?.length > 0 ? (
              orderList
                .filter((ord) => ord.order_id) 
                .sort((a, b) => new Date(b.created_on) - new Date(a.created_on)) 
                .filter((ord) =>
                  search !== ""
                    ? ord.order_id
                        .toString()
                        .toLowerCase()
                        .includes(search.toString().toLowerCase())
                    : true
                )
                .map((item, ind) => (
                  <TableRow key={item.id}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                      }}
                    >
                      {ind + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => handleModalChange(item)}
                    >
                      {item.order_id}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "none",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        {item?.user_details?.first_name +
                          " " +
                          item?.user_details?.last_name}
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }}>
                        {item?.user_details?.mobileno}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                      }}
                    >
                      {formatDate(item.created_on)}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                      }}
                    >
                      Rs {item.total_amount}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "left",
                          width: "200px",
                          height: "32px",
                          backgroundColor:
                            item.status === "declined"
                              ? "#FFF1F2"
                              : item.status === "delivered"
                              ? "#F6FFED"
                              : item.status === "pending"
                              ? "#E6F1F8"
                              : item.status === "delivery_partner_assigned"
                              ? "#FFF7EB"
                              : "#F9FAFB",
                          padding: "5px",
                          color:
                            item.status === "declined"
                              ? "#E11D48"
                              : item.status === "delivered"
                              ? "#52C41A"
                              : item.status === "pending"
                              ? "#0071BC"
                              : item.status === "delivery_partner_assigned"
                              ? "#FBB03B"
                              : item.status === "cancelled"?"red":"#6B7280",
                          borderColor:
                            item.status === "declined"
                              ? "#E11D48"
                              : item.status === "delivered"
                              ? "#F6FFED"
                              : item.status === "pending"
                              ? "#E6F1F8"
                              : item.status === "delivery_partner_assigned"
                              ? "#FBB03B"
                              : item.status === "cancelled"?"red":"#F9FAFB",
                          border: "1px solid",
                        }}
                      >
                        {item.cancel_request==true?"Cancel Request":item.status}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                      }}
                    >
                      {handlePartner(item.delivery_partner_id)}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                {loading ? (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      height: "200px",
                      fontWeight: "700",
                    }}
                    colSpan={7}
                  >
                    <Box>
                      <Box sx={{ width: "80%" }}>
                        <Skeleton
                          animation="wave"
                          sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }}
                        />
                        <Skeleton
                          animation="wave"
                          sx={{ background: "rgb(0 0 0 / 6%)" }}
                        />
                      </Box>
                      <Box sx={{ width: "80%" }}>
                        <Skeleton
                          animation="wave"
                          sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }}
                        />
                        <Skeleton
                          animation="wave"
                          sx={{ background: "rgb(0 0 0 / 6%)" }}
                        />
                      </Box>
                      <Box sx={{ width: "80%" }}>
                        <Skeleton
                          animation="wave"
                          sx={{ height: "60px", background: "rgb(0 0 0 / 6%)" }}
                        />
                        <Skeleton
                          animation="wave"
                          sx={{ background: "rgb(0 0 0 / 6%)" }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      height: "200px",
                      fontWeight: "700",
                    }}
                    colSpan={7}
                  >
                    No orders Available
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
